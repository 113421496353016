// react core
import { useContext, useState } from "react";

// material design
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
// import Modal from "@mui/material/Modal";

// fonts and icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp as iconUp } from "@fortawesome/pro-duotone-svg-icons";
import { faChevronDown as iconDown } from "@fortawesome/pro-duotone-svg-icons";
import { faQrcode as iconTickets } from "@fortawesome/pro-duotone-svg-icons";
import { faAd as iconAds } from "@fortawesome/pro-duotone-svg-icons";
import { faRunning as iconRunner } from "@fortawesome/pro-duotone-svg-icons";
import { faCog as iconSettings } from "@fortawesome/pro-regular-svg-icons";
import { faToggleOn as iconCriteria } from "@fortawesome/pro-duotone-svg-icons";
import { faImage as iconBanner } from "@fortawesome/pro-duotone-svg-icons";
import { faImages as iconGallery } from "@fortawesome/pro-duotone-svg-icons";
import { faInfoCircle as iconInfo } from "@fortawesome/pro-duotone-svg-icons";
import { faUsers as iconMembers } from "@fortawesome/pro-duotone-svg-icons";
import { faCircle as iconCircle } from "@fortawesome/pro-solid-svg-icons";
import { faTimes as iconClose } from "@fortawesome/pro-regular-svg-icons";
// import { faCalendarAlt as iconDates } from "@fortawesome/pro-duotone-svg-icons";

// entzy resources
import configEntzy from "components/config/ConfigEntzy";

// entzy context and services
import { MainContext } from "components/main/MainContext";
import { EventContext } from "pages/events/EventContext";
import { ActionBoxButton } from "components/utils/common/CommonButtons";
import { ActionLoader } from "components/utils/common/CommonLoaders";

// entzy room sub components
// import ActionCore from "../actions/ActionCore";

function RoomRunnerZone(props) {
  const user = props.user;
  const mainContext = useContext(MainContext);
  const eventContext = useContext(EventContext);

  const [showActionSections, setShowActionSections] = useState(false);
  const [showActions, setShowActions] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleShowSections = () => {
    setShowActionSections(!showActionSections);
    setShowActions(false);
  };

  const handleActionToggle = (section) => {
    setLoading(true);
    if (mainContext.state.actionDrawer.open) {
      setShowActions(false);
      mainContext.setActionDrawer({
        open: false,
        params: {},
      });
      setLoading(false);
    } else {
      setShowActions(true);
      setTimeout(() => {
        mainContext.setActionDrawer({
          open: true,
          params: {
            room: "launchpad",
            section: section.id,
          },
        });
        setTimeout(() => {
          setLoading(false);
        }, 200);
      }, 100);
    }
  };

  const sections = props.sections || [];
  const sectionList = [
    { id: "criteria", text: "Edit Launch Criteria", icon: iconCriteria },
    { id: "details", text: "Edit Launch Details", icon: iconInfo },
    { id: "banner", text: "Edit Image Banner", icon: iconBanner },
    { id: "gallery", text: "Edit Image Gallery", icon: iconGallery },
    { id: "tickets", text: "Edit Ticket Types", icon: iconTickets },
    { id: "members", text: "Manage Members", icon: iconMembers },
    { id: "socials", text: "Social Boosters", icon: iconAds },
  ];

  return (
    <Box className="box-default" sx={{ pb: configEntzy.APP_SPACING_XS3X }}>
      <Box
        className="box-default action-pointer text-left"
        sx={{
          p: configEntzy.APP_SPACING_MD,
        }}
        onClick={handleShowSections}
        hidden={!user.connected || !eventContext.state.event.manager}
      >
        <Typography variant="subtitle2">
          <span className="fa-layers fa-fw fa-3x">
            <FontAwesomeIcon icon={iconCircle} color="black" />
            <FontAwesomeIcon
              icon={iconSettings}
              color="white"
              transform="shrink-8"
            />
          </span>
        </Typography>
      </Box>

      <Drawer
        open={showActionSections}
        anchor="bottom"
        onClose={handleShowSections}
        sx={{
          zIndex: 999,
          "& .MuiDrawer-paper": {
            backgroundColor: "transparent",
          },
        }}
      >
        {/* <Modal open={showActionSections} onClose={handleShowSections}>
        <Box
          className="box-abs bg-black-t90 text-center"
          sx={{
            width: configEntzy.AVATAR_CONTAINER_XL,
            maxWidth: "100%",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            p: configEntzy.APP_SPACING_MD2X,
            borderRadius: configEntzy.BORDER_SIZE_XL,
            zIndex: 0,
          }}
        > */}
        <Box
          className={
            loading
              ? "box-default full-height bg-black-t75"
              : "box-default bg-black"
          }
        >
          <Box
            className="box-default text-right action-pointer"
            sx={{
              pt: configEntzy.APP_SPACING_MD2X,
              pr: configEntzy.APP_SPACING_MD2X,
            }}
            onClick={handleShowSections}
          >
            <Typography variant="h6" color="white">
              {!showActionSections && (
                <FontAwesomeIcon
                  icon={showActionSections ? iconUp : iconDown}
                  transform="left-18 grow-4"
                  color="white"
                />
              )}
              <FontAwesomeIcon
                icon={showActionSections ? iconClose : iconSettings}
                transform={showActionSections ? "grow-12 left-6" : "grow-4"}
                color="white"
              />
            </Typography>
          </Box>
          <Box className="box-default" hidden={loading}>
            <Typography variant="h6" color="primary">
              Runner Zone
            </Typography>
            <Box className="box-default">
              <Typography variant="subtitle2" color="white">
                Manage your eventuator
              </Typography>
            </Box>
          </Box>

          <Box className="box-default">
            <Box
              className="box-default"
              sx={{
                pb: configEntzy.APP_SPACING_HL,
              }}
            >
              <Box
                className="box-default"
                sx={{
                  pt: configEntzy.APP_SPACING_MD2X,
                  pb: configEntzy.APP_SPACING_MD2X,
                  opacity: 0.5,
                }}
              >
                <Typography variant="h6">
                  <FontAwesomeIcon icon={iconRunner} size="2x" fixedWidth />
                  <FontAwesomeIcon
                    icon={iconSettings}
                    size="2x"
                    className="fa-spin"
                    style={{
                      "--fa-animation-duration": showActions ? "0.5s" : "1s",
                      "--fa-animation-iteration-count": "1",
                    }}
                    fixedWidth
                  />
                </Typography>
              </Box>
              <Box
                className="box-default"
                sx={{
                  pl: configEntzy.APP_SPACING_M2L,
                  pr: configEntzy.APP_SPACING_M2L,
                }}
              >
                {loading ? (
                  <Box
                    className="box-default"
                    sx={{
                      pt: configEntzy.APP_SPACING_HL3X,
                    }}
                  >
                    <ActionLoader />
                  </Box>
                ) : (
                  <Container maxWidth="sm">
                    {sectionList.map((section) => {
                      return (
                        <Box
                          key={section.id}
                          className="box-default"
                          sx={{
                            pb: configEntzy.APP_SPACING_SM2X,
                          }}
                        >
                          <Box
                            className={
                              "box-default" +
                              (sections.includes(section.id)
                                ? " shadow-highlight"
                                : "")
                            }
                            sx={{
                              p: configEntzy.APP_SPACING_XS2X,
                            }}
                          >
                            <ActionBoxButton
                              size="medium"
                              bgColor="dusk.dark"
                              color="white"
                              text={section.text}
                              onClick={() => handleActionToggle(section)}
                              endIcon={
                                <FontAwesomeIcon
                                  icon={
                                    mainContext.state.actionDrawer.open &&
                                    mainContext.state.actionDrawer.params
                                      .section === section.id
                                      ? iconClose
                                      : section.icon
                                  }
                                  fixedWidth
                                  transform="grow-8 left-2"
                                />
                              }
                            />
                          </Box>
                        </Box>
                      );
                    })}
                  </Container>
                )}
              </Box>
            </Box>
            {/* <Box
                className="box-default"
                sx={{
                  pt: configEntzy.APP_SPACING_MD,
                  pb: configEntzy.APP_SPACING_MD2X,
                  opacity: 0.5,
                }}
              >
                <Typography variant="subtitle1">
                  <em>Use the highlighted sections to edit this area</em>
                </Typography>
              </Box> */}
          </Box>
          {/* <Box
              className="box-default"
              sx={{
                p: configEntzy.APP_SPACING_MD2X,
              }}
            >
              <ActionCore {...props} room="dates" icon={iconDates} />
            </Box> */}
        </Box>
        {/* </Box>
      </Modal> */}
      </Drawer>
    </Box>
  );
}

export default RoomRunnerZone;

// graphql queries
export const viewCalendarTimelineList = `query ViewCalendarTimelineList(
	$Year: String!
	$Month: String
	$limit: Int
	$nextToken: String
) {
	viewCalendarTimelineList(
		Year: $Year
		Month: $Month
		limit: $limit
		nextToken: $nextToken
	) {
		items{
			UserId
			DateId
			DateKey
			Category
			Description
			EventId
			EventUserId
			EventUrl
			EventCountry
			EventCurrency
			TicketId
			TicketUserId
			TicketDescription
			TicketCurrency
			TicketQuantity
			TicketUnitAmount
			TicketTotalAmount
			TicketEntryDate
			DateMeta
			LastUpdated
			FirstCreated
		},
		nextToken,
		itemsCount,
		itemsQuery
	}
}
`;

export const viewEvent = `query ViewEvent(
	$Url: String!
) {
	viewEvent(
		Url: $Url
	) {
		EventId
		UserId
		Url
		Active
		Access
		Country
		Currency
		Details
		WelcomeDetails
		LaunchPerTicket
		Logo
		Privacy
		Tagline
		Dates{
			id
			tag
			subtag
			order
			active
			base
			add
		}
		Triggers{
			id
			tag
			subtag
			order
			active
			unit
			min
		}
		Limits{
			id
			tag
			subtag
			order
			active
			name
			unit
			max
		}
		TicketTypes{
			id
			tag
			subtag
			order
			active
			name
			price
			min
			max
		}
		ImageFrames{
			id
			tag
			subtag
			order
			active
			path
		}
		Badges{
			id
			tag
			subtag
			order
			active
			name
			price
			max
			description
		}
		Ticking{
			DateKey
			Currency
			Quantity
			Amount
			Breakdown
			Meta
			Ticket
			TickStatus
			LastUpdated
			FirstCreated
		}
		Socials{
				SocialId
				SocialAction
				SocialStatus
				PostId
				PostUrl
				Meta
				LastUpdated
		}
		ContentModeration
		LastUpdated
		FirstCreated
	}
}
`;
export const viewEvents = `query ViewEvents(
	$limit: Int
	$nextToken: String
) {
	viewEvents(
		limit: $limit
		nextToken: $nextToken
	) {
		items{
			EventId
			UserId
			Url
			Access
			Country
			Currency
			Details
			Logo
		},
		nextToken,
		itemsCount,
		itemsQuery
	}
}
`;
export const viewAccess = `query ViewAccess(
	$EventId: String!
	$limit: Int
	$nextToken: String
) {
	viewAccess(
		EventId: $EventId
		limit: $limit
		nextToken: $nextToken
	) {
		items{
			UserId
			SubId
			Name
			Email
			Status
		},
		nextToken,
		itemsCount,
		itemsQuery
	}
}
`;
export const viewMembers = `query ViewMembers(
	$EventId: String!
	$MemberType: String!
	$limit: Int
	$nextToken: String
) {
	viewMembers(
		EventId: $EventId
		MemberType: $MemberType
		limit: $limit
		nextToken: $nextToken
	) {
		items{
			EventId
			UserId
			GroupStatus
		},
		nextToken,
		itemsCount,
		itemsQuery
	}
}
`;
export const viewJoin = `query ViewJoin(
	$Url: String!
) {
	viewJoin(
		Url: $Url
	)
}
`;
export const viewGroup = `query ViewGroup(
	$EventId: String!
	$TicketId: String!
) {
	viewGroup(
		EventId: $EventId
		TicketId: $TicketId
	) {
		UserId
		UserEmail
		UserName
		UserViewer
		Category
		Quantity
		BillingQuantity
		PendingQuantity
		Status
		LastUpdated
		FirstCreated
	}
}
`;
export const viewManagers = `query ViewManagers(
	$EventId: String!
	$managerView: Boolean
	$limit: Int
	$nextToken: String
) {
	viewManagers(
		EventId: $EventId
		managerView: $managerView
		limit: $limit
		nextToken: $nextToken
	) {
		items{
			UserId
			SubId
			Name
			Avatar
			Email
			Status
			HasPayment
			HasPayout
		},
		nextToken,
		itemsCount,
		itemsQuery
	}
}
`;
export const viewTickets = `query ViewTickets(
	$EventId: String
	$TicketShortId: String
	$limit: Int
	$nextToken: String
) {
	viewTickets(
		EventId: $EventId
		TicketShortId: $TicketShortId
		limit: $limit
		nextToken: $nextToken
	) {
		items{
			EventId
			Url
			TicketId
			UserId
			Description
			Notice
			Currency
			Quantity
			UnitAmount
			TotalAmount
			TickedStatus
			TickedFinal
			TickedDates{
				id
				order
				active
				tag
				subtag
				base
				add
			}
			TypeMeta,
			FirstCreated
			LastUpdated
		},
		nextToken,
		itemsCount,
		itemsQuery
	}
}
`;
export const viewTicks = `query ViewTicks(
	$EventId: String!
	$DateKey: String!
	$TickStatus: String
	$limit: Int
	$nextToken: String
) {
	viewTicks(
		EventId: $EventId
		DateKey: $DateKey
		TickStatus: $TickStatus
		limit: $limit
		nextToken: $nextToken
	) {
		items{
			EventId
			TickId
			DateKey
			UserId
			UserName
			TicketId
			TicketTypeId
			TicketDescription
			Amount
			Quantity
			Currency
			TickStatus
			FirstCreated
			LastUpdated
		},
		nextToken,
		itemsCount,
		itemsQuery
	}
}
`;
export const viewTicket = `query ViewTicket(
	$EventId: String!
	$TicketId: String!
) {
  viewTicket(
		EventId: $EventId
		TicketId: $TicketId
	) {
		EventId
		TicketId
		TypeId
		UserId
		UserName
		UserEmail
		Url
		Quantity
		Description
		Currency
		UnitAmount
		TotalAmount
		Charge
		PartKey
		PartHash
		EntryDate
		Shareholders{
			UserId
			UserEmail
			UserName
			UserOwner
			UserViewer
			Category
			Quantity
			BillingQuantity
			PendingQuantity
			Status
			LastUpdated
			FirstCreated
		}
		TickedStatus
		TickedDates{
			id
			order
			active
			tag
			subtag
			base
			add
		}
  }
}
`;

export const viewBadges = `query ViewBadges(
	$EventId: String!
	$BadgeId: String
	$limit: Int
	$nextToken: String
) {
	viewBadges(
		EventId: $EventId
		BadgeId: $BadgeId
		limit: $limit
		nextToken: $nextToken
	) {
		items{
			EventId
			UserBadgeId
			BadgeId
			UserId
			UserName
			UserEmail
			BadgeName
			BadgeDescription
			BadgeStatus
			BadgeExpires
			Url
			Currency
			Amount
			Charge
			PartKey
			EntryStatus
			LastUpdated
			FirstCreated
		},
		nextToken,
		itemsCount,
		itemsQuery
	}
}
`;

export const viewDateList = `query ViewDateList(
	$EventId: String!
	$DateKey: String!
	$limit: Int
	$nextToken: String
) {
	viewDateList(
		EventId: $EventId
		DateKey: $DateKey
		limit: $limit
		nextToken: $nextToken
	) {
		items{
			EventId
			TicketId
			TypeId
			UserId
			UserName
			UserEmail
			Url
			Quantity
			Description
			Currency
			TotalAmount
			EntryDate
			TickedStatus
			FirstCreated
			LastUpdated
		},
		nextToken,
		itemsCount,
		itemsQuery
	}
}
`;
export const viewPayout = `query ViewPayout {
  viewPayout
}
`;
export const viewLinks = `query ViewLinks(
	$EventId: String!
	$LinkId: String
	$LinkType: String!
	$limit: Int
	$nextToken: String
) {
	viewLinks(
		EventId: $EventId
		LinkId: $LinkId
		LinkType: $LinkType
		limit: $limit
		nextToken: $nextToken
	) {
		items{
			EventId
			LinkId
			LinkType
			LinkStatus
			LinkTarget
			UserId
			Title
			Description
			Category
			ContentModeration
			FirstCreated
			LastUpdated
		},
		nextToken,
		itemsCount,
		itemsQuery
	}
}
`;
export const publicViewLinks = `query PublicViewLinks(
	$EventId: String!
	$LinkId: String
	$LinkType: String!
	$limit: Int
	$nextToken: String
) {
	publicViewLinks(
		EventId: $EventId
		LinkId: $LinkId
		LinkType: $LinkType
		limit: $limit
		nextToken: $nextToken
	) {
		items{
			EventId
			LinkId
			LinkType
			LinkStatus
			LinkTarget
			UserId
			Title
			Description
			Category
			ContentModeration
			FirstCreated
			LastUpdated
		},
		nextToken,
		itemsCount,
		itemsQuery
	}
}
`;

export const getTimeline = `query GetTimeline(
	$Year: String!
	$Month: String
	$limit: Int
	$nextToken: String
) {
	getTimeline(
		Year: $Year
		Month: $Month
		limit: $limit
		nextToken: $nextToken
	) {
		items{
			UserId
			DateId
			DateKey
			Category
			EventId
			EventUserId
			EventUrl
			EventCountry
			EventCurrency
			TicketId
			TicketUserId
			TicketDescription
			TicketCurrency
			TicketQuantity
			TicketUnitAmount
			TicketTotalAmount
			TicketEntryDate
			DateMeta
			LastUpdated
			FirstCreated
		},
		nextToken,
		itemsCount,
		itemsQuery
	}
}
`;

export const getLaunchesCountries = `query GetLaunchesCountries(
	$Year: String!
	$Month: String!
	$limit: Int
	$nextToken: String
) {
	getLaunchesCountries(
		Year: $Year
		Month: $Month
		limit: $limit
		nextToken: $nextToken
	) {
		items{
			Key
			Value
		},
		nextToken,
		itemsCount,
		itemsQuery
	}
}
`;
export const getLaunchesLeague = `query GetLaunchesLeague(
	$Category: String!
	$Country: String!
	$Year: String!
	$Month: String!
	$limit: Int
	$nextToken: String
) {
	getLaunchesLeague(
		Category: $Category
		Country: $Country
		Year: $Year
		Month: $Month
		limit: $limit
		nextToken: $nextToken
	) {
		items{
			UserId
			Country
			RankingKey
			RankingValue
		},
		nextToken,
		itemsCount,
		itemsQuery
	}
}
`;
export const getLaunchesTimeline = `query GetLaunchesTimeline(
	$Year: String!
	$Month: String
	$limit: Int
	$nextToken: String
) {
	getLaunchesTimeline(
		Year: $Year
		Month: $Month
		limit: $limit
		nextToken: $nextToken
	) {
		items{
			DateKey
			Category
			UserId
			EventId
			TicketId
			DateTrigger
			DateEntry
			Url
			Country
		},
		nextToken,
		itemsCount,
		itemsQuery
	}
}
`;

export const getUser = `query GetUser {
  getUser {
		UserId
		Email
		Name
		Avatar
		PaymentId
		PayoutId
		IdentityProvider
		FirstCreated
		LastUpdated
		LastConnect
  }
}
`;
export const getUserDetails = `query GetUserDetails(
	$UserId: String!
) {
  getUserDetails(
		UserId: $UserId
	) {
		UserId
		SubId
		Name
		Avatar
		Status
		HasPayment
		HasPayout
	}
}
`;
export const getUserName = `query GetUserName(
	$UserName: String!
) {
  getUserName(
		UserName: $UserName
	)
}
`;
export const getUserCheckName = `query GetUserCheckName(
	$UserName: String!
) {
  getUserCheckName(
		UserName: $UserName
	)
}
`;

export const getUserSetting = `query GetUserSetting(
	$SettingId: String!
) {
  getUserSetting(
		SettingId: $SettingId
	) {
		SettingId
		UserId
		SettingValue
		LastUpdated
		FirstCreated
  }
}
`;
export const getInterest = `query GetInterest(
	$Category: String!
	$SubCategory: String
	$EventId: String
	$TicketId: String
	$RoomId: String
	$ActivityId: String
) {
  getInterest(
		Category: $Category
		SubCategory: $SubCategory
		EventId: $EventId
		TicketId: $TicketId
		RoomId: $RoomId
		ActivityId: $ActivityId
	) {
		InterestId
		LastUpdated
		FirstCreated
  }
}
`;
export const getInterestList = `query GetInterestList(
	$Category: String!
	$SubCategory: String
	$ThirdCategory: String
	$FourthCategory: String
	$HashFilter: String
) {
  getInterestList(
		Category: $Category
		SubCategory: $SubCategory
		ThirdCategory: $ThirdCategory
		FourthCategory: $FourthCategory
		HashFilter: $HashFilter
	) {
		items{
			UserId
			InterestId
			HashId
			SortId
			Url
			FirstCreated
			LastUpdated
		},
		nextToken,
		itemsCount,
		itemsQuery
	}
}
`;

export const getUserTransaction = `query GetUserTransaction(
	$TransactionId: String!
) {
  getUserTransaction(
		TransactionId: $TransactionId
	) {
		UserId
		TransactionId
		TransactionType
		TransactionStatus
		IdempotencyId
		SenderId
		SenderType
		SenderName
		RecipientId
		RecipientType
		RecipientName
		Currency
		Description
		Amount
		Fee
		EventId
		TicketId
		BadgeId
		EntryDate
		LastUpdated
		FirstCreated
	}
}
`;
export const getUserTransactionList = `query GetUserTransactionList(
	$limit: Int
	$nextToken: String
) {
	getUserTransactionList(
		limit: $limit
		nextToken: $nextToken
	) {
		items{
			UserId
			TransactionId
			TransactionType
			TransactionStatus
			IdempotencyId
			SenderId
			SenderType
			SenderName
			RecipientId
			RecipientType
			RecipientName
			Currency
			Description
			Amount
			Fee
			EventId
			TicketId
			BadgeId
			EntryDate
			LastUpdated
			FirstCreated
		},
		nextToken,
		itemsCount,
		itemsQuery
	}
}
`;

export const getUserProduct = `query GetUserProduct(
	$Name: String!
	$ProductId: String!
) {
  getUserProduct(
		Name: $Name
		ProductId: $ProductId
	) {
		SellerId
		ProductId
		List
		Category
		Currency
		Amount
		Description
		ProductStatus
		LastUpdated
		FirstCreated
	}
}
`;
export const getUserProductList = `query GetUserProductList(
	$Name: String!
	$List: String!
	$Category: String
	$limit: Int
	$nextToken: String
) {
	getUserProductList(
		Name: $Name
		List: $List
		Category: $Category
		limit: $limit
		nextToken: $nextToken
	) {
		items{
			SellerId
			ProductId
			List
			Category
			Currency
			Amount
			Description
			ProductStatus
			LastUpdated
			FirstCreated
		},
		DataManager,
		nextToken,
		itemsCount,
		itemsQuery
	}
}
`;
export const getEventProduct = `query GetEventProduct(
	$Url: String!
	$ProductId: String!
) {
  getEventProduct(
		Url: $Url
		ProductId: $ProductId
	) {
		SellerId
		ProductId
		List
		Category
		Currency
		Amount
		Description
		ProductStatus
		LastUpdated
		FirstCreated
	}
}
`;
export const getEventProductList = `query GetEventProductList(
	$Url: String!
	$List: String!
	$Category: String
	$limit: Int
	$nextToken: String
) {
	getEventProductList(
		Url: $Url
		List: $List
		Category: $Category
		limit: $limit
		nextToken: $nextToken
	) {
		items{
			SellerId
			ProductId
			List
			Category
			Currency
			Amount
			Description
			ProductStatus
			LastUpdated
			FirstCreated
		},
		EventId,
		DataManager,
		nextToken,
		itemsCount,
		itemsQuery
	}
}
`;

export const getUserContact = `query GetUserContact(
	$ContactUserId: String!
) {
  getUserContact(
		ContactUserId: $ContactUserId
	) {
		UserId
		ContactUserId
		LastUpdated
		FirstCreated
	}
}
`;
export const getUserContactList = `query GetUserContactList(
	$limit: Int
	$nextToken: String
) {
	getUserContactList(
		limit: $limit
		nextToken: $nextToken
	) {
		items{
			UserId
			ContactUserId
			LastUpdated
			FirstCreated
		},
		nextToken,
		itemsCount,
		itemsQuery
	}
}
`;

export const getAccess = `query GetAccess(
	$EventId: String!
) {
  getAccess(
		EventId: $EventId
	) {
		EventId
		UserId
		UserStatus
  }
}
`;
export const getAccessWaiting = `query GetAccessWaiting(
	$EventId: String!
) {
  getAccessWaiting(
		EventId: $EventId
	)
}
`;

export const getEventLogo = `query GetEventLogo(
	$EventId: String!
) {
  getEventLogo(
		EventId: $EventId
	)
}
`;

export const getChatList = `query GetChatList(
	$EventId: String!
	$Category: String
	$limit: Int
	$nextToken: String
) {
	getChatList(
		EventId: $EventId
		Category: $Category
		limit: $limit
		nextToken: $nextToken
	) {
		items{
			ChatId
			UserId
			UserName
			UserStatus
			EventId
			Url
			Message
			Category
			Aged
			LastRead
			Files{
				FileId
				FileName
				FileType
			}
			Places{
				PlaceId
				PlaceName
				PlaceCoordinates
			}
			Mentions{
				MentionId
				MentionName
				MentionUserId
			}
			FirstCreated
			LastUpdated
		},
		nextToken,
		itemsCount,
		itemsQuery
	}
}
`;

export const getChatEventList = `query GetChatEventList(
	$EventId: String!
	$RoomName: String!
	$Category: String
	$DateFragment: String
	$limit: Int
	$nextToken: String
) {
	getChatEventList(
		EventId: $EventId
		RoomName: $RoomName
		DateFragment: $DateFragment
		Category: $Category
		limit: $limit
		nextToken: $nextToken
	) {
		items{
			RoomId
			PostId
			UserId
			EventId
			RoomArea
			RoomName
			Category
			ContentType
			ContentData
			ContentImage
			ContentModeration
			InReplyTo
			PostDeleted
			FirstCreated
			LastUpdated
		},
		nextToken,
		itemsCount,
		itemsQuery
	}
}
`;
export const getChatDirectList = `query GetChatDirectList(
	$RoomArea: String!
	$RoomName: String!
	$Category: String
	$DateFragment: String
	$limit: Int
	$nextToken: String
) {
	getChatDirectList(
		RoomArea: $RoomArea
		RoomName: $RoomName
		DateFragment: $DateFragment
		Category: $Category
		limit: $limit
		nextToken: $nextToken
	) {
		items{
			RoomId
			PostId
			UserId
			EventId
			RoomArea
			RoomName
			Category
			ContentType
			ContentData
			ContentImage
			ContentModeration
			InReplyTo
			PostDeleted
			FirstCreated
			LastUpdated
		},
		nextToken,
		itemsCount,
		itemsQuery
	}
}
`;

export const getNotifications = `query GetNotifications(
	$Category: String
	$limit: Int
	$nextToken: String
) {
  getNotifications(
		Category: $Category
		limit: $limit
		nextToken: $nextToken
	) {
		items{
			UserId
			NotificationId
			MessageCategory
			MessageTitle
			MessageBody
			MessageUrl
			MessageRead
			LastUpdated
			FirstCreated
		},
		nextToken,
		itemsCount,
		itemsQuery
	}
}
`;
export const getChatAlerts = `query GetChatAlerts(
	$EventId: String!
) {
  getChatAlerts(
		EventId: $EventId
	) {
		SettingId
		UserId
		UserName
		UserEmail
		EventId
		Url
		Settings
		LastUpdated
		FirstCreated
	}
}
`;
export const getChatUnread = `query GetChatUnread(
	$EventId: String!
) {
  getChatUnread(
		EventId: $EventId
	)
}
`;
export const getBatchChatUnread = `query GetBatchChatUnread(
	$EventList: String!
) {
  getBatchChatUnread(
		EventList: $EventList
	)
}
`;

// public graphql queries
export const publicViewEvent = `query PublicViewEvent(
	$Url: String!
) {
	publicViewEvent(
		Url: $Url
	) {
		EventId
		UserId
		Url
		Active
		Access
		Country
		Currency
		Details
		WelcomeDetails
		LaunchPerTicket
		Logo
		Privacy
		Tagline
		Dates{
			id
			tag
			subtag
			order
			active
			base
			add
		}
		Triggers{
			id
			tag
			subtag
			order
			active
			unit
			min
		}
		Limits{
			id
			tag
			subtag
			order
			active
			name
			unit
			max
		}
		TicketTypes{
			id
			tag
			subtag
			order
			active
			name
			price
			min
			max
		}
		Badges{
			id
			tag
			subtag
			order
			active
			name
			price
			max
			description
		}
		ImageFrames{
			id
			tag
			subtag
			order
			active
			path
		}
		Ticking{
			DateKey
			Currency
			Quantity
			Amount
			Breakdown
			Meta
			Ticket
			TickStatus
			LastUpdated
			FirstCreated
		}
		Socials{
				SocialId
				SocialAction
				SocialStatus
				PostId
				PostUrl
				Meta
				LastUpdated
		}
		ContentModeration
		LastUpdated
		FirstCreated
	}
}
`;
export const publicValidateCaptcha = `query PublicValidateCaptcha(
	$Token: String!
	$Version: String!
) {
	publicValidateCaptcha(
		Token: $Token
		Version: $Version
	)
}
`;

export const testQuery = `query TestQuery($Var: String!) {
	testQuery(Var: $Var) {
		Var
		Result
	}
}
`;
export const publicTest = `query PublicTest {
	publicTest
}
`;

export const publicGetUserProduct = `query PublicGetUserProduct(
	$Name: String!
	$ProductId: String!
) {
  publicGetUserProduct(
		Name: $Name
		ProductId: $ProductId
	) {
		SellerId
		ProductId
		List
		Category
		Currency
		Amount
		Description
		ProductStatus
		LastUpdated
		FirstCreated
	}
}
`;
export const publicGetUserProductList = `query PublicGetUserProductList(
	$Name: String!
	$List: String!
	$Category: String
	$limit: Int
	$nextToken: String
) {
	publicGetUserProductList(
		Name: $Name
		List: $List
		Category: $Category
		limit: $limit
		nextToken: $nextToken
	) {
		items{
			SellerId
			ProductId
			List
			Category
			Currency
			Amount
			Description
			ProductStatus
			LastUpdated
			FirstCreated
		},
		DataManager,
		nextToken,
		itemsCount,
		itemsQuery
	}
}
`;
export const publicGetEventProduct = `query PublicGetEventProduct(
	$Url: String!
	$ProductId: String!
) {
  publicGetEventProduct(
		Url: $Url
		ProductId: $ProductId
	) {
		SellerId
		ProductId
		List
		Category
		Currency
		Amount
		Description
		ProductStatus
		LastUpdated
		FirstCreated
	}
}
`;
export const publicGetEventProductList = `query PublicGetEventProductList(
	$Url: String!
	$List: String!
	$Category: String
	$limit: Int
	$nextToken: String
) {
	publicGetEventProductList(
		Url: $Url
		List: $List
		Category: $Category
		limit: $limit
		nextToken: $nextToken
	) {
		items{
			SellerId
			ProductId
			List
			Category
			Currency
			Amount
			Description
			ProductStatus
			LastUpdated
			FirstCreated
		},
		EventId,
		DataManager,
		nextToken,
		itemsCount,
		itemsQuery
	}
}
`;

export const publicGetPaymentIntent = `query PublicGetPaymentIntent(
	$Action: String!
	$RecipientType: String!
	$RecipientName: String!
	$Currency: String!
	$Amount: Int!
	$Email: String!
	$Description: String
	$PaymentIntentId: String
) {
  publicGetPaymentIntent(
		Action: $Action
		RecipientType: $RecipientType
		RecipientName: $RecipientName
		Currency: $Currency
		Amount: $Amount
		Email: $Email
		Description: $Description
		PaymentIntentId: $PaymentIntentId
	)
}
`;

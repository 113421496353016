// graphql subscriptions
export const onDeltaEvent = `subscription OnDeltaEvent(
	$Url: String!
) {
	onDeltaEvent(
		Url: $Url
	) {
		EventId
		UserId
		Url
		Active
		Access
		Country
		Currency
		Details
		WelcomeDetails
		LaunchPerTicket
		Logo
		Privacy
		Tagline
		Dates{
			id
			tag
			subtag
			order
			active
			base
			add
		}
		Triggers{
			id
			tag
			subtag
			order
			active
			unit
			min
		}
		Limits{
			id
			tag
			subtag
			order
			active
			name
			unit
			max
		}
		TicketTypes{
			id
			tag
			subtag
			order
			active
			name
			price
			min
			max
		}
		Badges{
			id
			tag
			subtag
			order
			active
			name
			price
			max
			description
		}
		ImageFrames{
			id
			tag
			subtag
			order
			active
			path
		}
		Ticking{
			DateKey
			Currency
			Quantity
			Amount
			Breakdown
			Meta
			Ticket
			TickStatus
		}
		Socials{
				SocialId
				SocialAction
				SocialStatus
				PostId
				PostUrl
				Meta
				LastUpdated
		}
		ContentModeration
		LastUpdated
		FirstCreated
	}
}
`;
export const onDeltaSharing = `subscription OnDeltaSharing(
	$EventId: String!
) {
	onDeltaSharing(
		EventId: $EventId
	) {
		items{
				EventId
				LinkId
				LinkType
				LinkStatus
				LinkTarget
				UserId
				Title
				Description
				Category
				ContentModeration
				FirstCreated
				LastUpdated
		},
		nextToken,
		itemsCount,
		itemsQuery,
		EventId
	}
}
`;
export const onDeltaLocations = `subscription OnDeltaLocations(
	$EventId: String!
) {
	onDeltaLocations(
		EventId: $EventId
	) {
		items{
				EventId
				LinkId
				LinkType
				LinkStatus
				LinkTarget
				UserId
				Title
				Description
				Category
				ContentModeration
				FirstCreated
				LastUpdated
		},
		nextToken,
		itemsCount,
		itemsQuery,
		EventId
	}
}
`;
export const onNewNotification = `subscription OnNewNotification(
	$UserId: String!
) {
	onNewNotification(
		UserId: $UserId
	) {
		UserId
		NotificationId
		MessageCategory
		MessageTitle
		MessageBody
		MessageUrl
		MessageRead
		LastUpdated
		FirstCreated
	}
}
`;

export const onPostChatEventContent = `subscription OnPostChatEventContent(
	$EventId: String!
	$RoomName: String!
) {
	onPostChatEventContent(
		EventId: $EventId
		RoomName: $RoomName
	) {
		RoomId
		PostId
		UserId
		EventId
		RoomArea
		RoomName
		Category
		ContentType
		ContentData
		ContentImage
		ContentModeration
		InReplyTo
		PostDeleted
		FirstCreated
		LastUpdated
}
}
`;
export const onPostChatDirectContent = `subscription OnPostChatDirectContent(
	$RoomArea: String!
	$RoomName: String!
) {
	onPostChatDirectContent(
		RoomArea: $RoomArea
		RoomName: $RoomName
	) {
		RoomId
		PostId
		UserId
		EventId
		RoomArea
		RoomName
		Category
		ContentType
		ContentData
		ContentImage
		ContentModeration
		InReplyTo
		PostDeleted
		FirstCreated
		LastUpdated
}
}
`;

export const onPostChat = `subscription OnPostChat(
	$EventId: String!
) {
	onPostChat(
		EventId: $EventId
	) {
		ChatId
		UserId
		UserName
		UserStatus
		EventId
		Url
		Message
		Category
		SourceId
		Aged
		Files{
			FileId
			FileName
			FileType
		}
		Places{
			PlaceId
			PlaceName
			PlaceCoordinates
		}
		Mentions{
			MentionId
			MentionName
			MentionUserId
		}
		FirstCreated
		LastUpdated
	}
}
`;

export const onEntryHandshake = `subscription OnEntryHandshake(
	$EventId: String!
) {
	onEntryHandshake(
		EventId: $EventId
	) {
		EventId
		TicketId
		TicketUserId
		TicketAction
		TicketStatus
		TicketQuantity
		Shareholders{
			TicketId
			UserId
			UserEmail
			UserName
			UserOwner
			UserViewer
			Category
			Quantity
			BillingQuantity
			PendingQuantity
			Status
			LastUpdated
			FirstCreated
		}
		EntryUserId
		EntryDate
		EntryStatus
	}
}
`;

export const onValidateTicket = `subscription OnValidateTicket(
	$EventId: String!
	$TicketId: String!
) {
	onValidateTicket(
		EventId: $EventId
		TicketId: $TicketId
	) {
		EventId
		TicketId
		TypeId
		UserId
		UserName
		UserEmail
		Url
		Quantity
		Description
		Currency
		UnitAmount
		TotalAmount
		Charge
		PartKey
		EntryDate
		Shareholders{
			UserId
			UserEmail
			UserName
			UserOwner
			UserViewer
			Category
			Quantity
			BillingQuantity
			PendingQuantity
			Status
			LastUpdated
			FirstCreated
		}
		TickedStatus
		TickedDates{
			id
			order
			active
			tag
			subtag
			base
			add
		}
	}
}
`;
export const onValidateBadge = `subscription OnValidateBadge(
	$EventId: String!
	$BadgeId: String!
) {
	onValidateBadge(
		EventId: $EventId
		BadgeId: $BadgeId
	) {
		EventId
		UserBadgeId
		BadgeId
		UserId
		UserName
		UserEmail
		BadgeName
		BadgeDescription
		BadgeStatus
		BadgeExpires
		Url
		Currency
		Amount
		Charge
		PartKey
		EntryStatus
		LastUpdated
		FirstCreated
	}
}
`;

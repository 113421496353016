// react core
import { useState, useReducer, useEffect, Fragment, useCallback } from "react";

// date handling
import dayjs from "dayjs";

// material design
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

// entzy event child pages
import MenuStart from "components/menu/MenuStart";
import EventCalendar from "./EventCalendar";
import EventList from "./EventList";
import EventRunner from "./EventRunner";

// idle timer context
import { IdleTimerProvider } from "react-idle-timer";

// entzy context
import {
  EventContext,
  initialState,
  eventReducer,
  eventActions,
  eventObservables,
} from "./EventContext";
import {
  EventTicketLaunchStates,
  EventImageFrames,
  EVENT_CONSTANTS,
} from "models/Event";
import {
  idGenerator,
  scrollIntoViewIfNeeded,
  closeAction,
  jsonTryParse,
} from "models/Tools";

// entzy config and services
import configEntzy from "components/config/ConfigEntzy";
import { serviceGraphCall, serviceUserSetting } from "services/graphql/call";

// entzy hooks
import { userUpdate, userGet } from "hooks/identity/identityState";

function EventIndex(props) {
  const user = props.user;
  // const [eventName] = useState(props.eventName);
  const [pause, setPause] = useState(false);
  const [state, dispatch] = useReducer(eventReducer, initialState);

  // idle timer actions
  const onPrompt = () => {
    // optional modal
  };
  const onIdle = () => {
    setPause(true);
  };
  const onActive = (event) => {
    setPause(false);
  };
  const onAction = (event) => {
    // watched events
  };

  // user related functions
  const getUserConnected = (params) => {
    return state.user && state.user.connected
      ? true
      : params.user && params.user.connected
      ? true
      : user && user.connected
      ? true
      : false;
  };
  const cbGetUserConnected = useCallback(getUserConnected, [state.user, user]);

  // on-demand functions that require state
  const hangImageFrames = () => {
    const imageFrames = EventImageFrames();
    imageFrames.forEach((frame) => {
      const frameData = state.event.data.ImageFrames
        ? state.event.data.ImageFrames.filter((obj) => obj.id === frame.id)
        : [];
      if (frameData && frameData.length > 0) {
        frame.path = frameData[0].path;
        frame.active = frameData[0].active;
      }
    });
    return imageFrames;
  };

  // service dependent async functions
  const prepareCreateEvent = async (params) => {
    let response;
    const userConnected = getUserConnected(params);
    if (userConnected) {
      // attempt create event server side when user connected
      response = await serviceGraphCall("mutation", "createEvent", {
        Name: params.name,
        Country: params.country,
        Currency: params.currency,
      });
      if (!response.success) {
        response.alert = true;
        return response;
      }
      // capture the eventData and set any first create defaults
      const eventData = response.data;
      eventData.Badges = [];
      eventData.Ticking = [];
      // create content object as owner
      const eventContent = {
        data: eventData,
        owner: true,
        manager: true,
        accessGranted: true,
      };
      // once created run the pin event process to setup things like notifications
      await preparePinEvent({
        EventId: eventContent.data.EventId,
        pinned: true,
      });
      return eventContent;
    } else {
      // when exploring return params as simple passthrough
      const eventData = {
        EventId: idGenerator(),
        Name: params.name,
        Country: params.country,
        Currency: params.currency,
      };
      return {
        data: eventData,
        owner: true,
        manager: true,
        exploring: true,
        accessGranted: true,
      };
    }
  };

  const prepareJoinEvent = async (params) => {
    // call to get event content by name
    let response;
    if (!params.name) {
      return {
        alert: true,
        message: "Event name required",
      };
    }
    response = await serviceGraphCall("query", "publicViewEvent", {
      Url: params.name,
    });
    if (!response.success) {
      response.alert = true;
      return response;
    }
    // valid event: establish base content
    const eventContent = {
      data: response.data,
      owner: false,
      manager: false,
    };
    const userConnected = getUserConnected(params);
    if (userConnected) {
      // check and enrich user access level if connected
      response = await serviceGraphCall("query", "getAccess", {
        EventId: eventContent.data.EventId,
      });
      if (!response.success) {
        response.alert = true;
        return response;
      }
      if (response.data) {
        if (response.data.UserStatus === "owner") {
          eventContent.owner = true;
          eventContent.manager = true;
          eventContent.accessGranted = true;
        } else if (response.data.UserStatus === "manager") {
          eventContent.manager = true;
          eventContent.accessGranted = true;
        } else if (response.data.UserStatus === "listed") {
          eventContent.accessGranted = true;
        } else if (response.data.UserStatus === "requested") {
          eventContent.accessQueued = true;
          eventContent.accessGranted = false;
        } else {
          eventContent.accessGranted = false;
        }
      } else {
        eventContent.accessGranted = false;
      }
    } else {
      eventContent.accessGranted = false;
    }
    if (eventContent.data.Access === "public") {
      eventContent.accessGranted = true;
    }
    // return event content to client
    return eventContent;
  };

  const prepareRequestJoinEvent = async (params) => {
    let response;
    const userConnected = getUserConnected(params);
    if (userConnected) {
      response = await serviceGraphCall("mutation", "requestJoinEvent", {
        EventId: params.EventId,
        Action: params.remove ? "retract" : "join",
      });
      if (!response.success) {
        response.alert = true;
        return response;
      }
      return {
        params: params,
        data: response.data,
      };
    } else {
      return {
        alert: true,
        message: "Please connect to place join requests",
      };
    }
  };

  const prepareUpdateEvent = async (update) => {
    let response;
    const userConnected = getUserConnected(update);
    if (userConnected) {
      // attempt the update server side when user connected
      const eventNewData = JSON.parse(JSON.stringify(state.event.data));
      eventNewData.Changed = [];
      if (update.multiple) {
        update.keysets.forEach((keyset) => {
          eventNewData[keyset.key] = keyset.value;
          eventNewData.Changed.push(keyset.key);
        });
      } else {
        eventNewData[update.key] = update.value;
        eventNewData.Changed.push(update.key);
      }
      response = await serviceGraphCall(
        "mutation",
        "updateEvent",
        eventNewData
      );
      if (!response.success) {
        response.alert = true;
        return response;
      }
      // return updated data including any server side handling of value
      const results = {};
      if (update.multiple) {
        results.multiple = true;
        results.keysets = [];
        update.keysets.forEach((keyset) => {
          results.keysets.push({
            key: keyset.key,
            value: response.data[keyset.key],
          });
        });
      } else {
        results.key = update.key;
        results.value = response.data[update.key];
      }
      return results;
    } else {
      // when exploring return update as simple passthrough
      return update;
    }
  };

  const preparePostSocials = async (params) => {
    let response, action;
    const userConnected = getUserConnected(params);
    if (userConnected) {
      action = params.delete
        ? "delete"
        : params.boost
        ? "boost"
        : params.repost
        ? "repost"
        : "post";
      response = await serviceGraphCall("mutation", "postEventSocials", {
        EventId: params.EventId,
        SocialId: params.SocialId,
        Action: action,
        PostId: params.PostId,
      });
      if (!response.success) {
        response.alert = true;
        return response;
      }
      response.data =
        typeof response.data === "string"
          ? jsonTryParse(response.data, response.data)
          : response.data;
      if (response.data.status === "error") {
        response.alert = true;
        response.message = response.data.message;
        return response;
      }
      return {
        params: params,
        data: response.data,
      };
    } else {
      return {
        alert: true,
        message: "Please connect to manage social media",
      };
    }
  };

  const prepareUpdatePlaces = async (update) => {
    let response;
    const userConnected = getUserConnected(update);
    if (userConnected) {
      const userPlace = {};
      userPlace.EventId = state.event.data.EventId;
      userPlace[update.key] = update.value;
      response = await serviceGraphCall("mutation", "updatePlaces", userPlace);
      if (!response.success) {
        response.alert = true;
        return response;
      }
      const results = response.data;
      return results;
    } else {
      return update;
    }
  };

  const preparePullMessages = async (params) => {
    let response;
    const userConnected = getUserConnected(params);
    if (userConnected) {
      response = await serviceGraphCall("query", "getChatEventList", {
        EventId: params.EventId,
        RoomName: params.RoomName,
        Category: params.Category,
        // DateFragment: params.DateFragment,
        // DateFragment: "runner",
        nextToken: params.nextToken,
        limit: configEntzy.PAGE_SIZE_MESSAGING,
      });
      if (!response.success) {
        response.alert = true;
        return response;
      }
      const dataItems = params.more
        ? state.messaging.rooms[
            state.messaging.activeRoom
          ].data.combined.concat(response.data.items)
        : response.data.items;
      return {
        roomName: params.RoomName,
        data: dataItems,
        nextToken: response.data.nextToken,
      };
    } else {
      return {
        roomName: params.RoomName,
        data: {
          combined: [],
          runners: [],
          riders: [],
        },
      };
    }
  };

  const preparePostMessage = async (post) => {
    // post group message
    let postMethod, response;
    const userConnected = getUserConnected(post);
    if (userConnected) {
      postMethod = post.RoomName.startsWith("ai")
        ? "postChatAiContent"
        : "postChatEventContent";
      response = await serviceGraphCall("mutation", postMethod, {
        EventId: post.EventId,
        RoomName: post.RoomName,
        ContentType: post.ContentType,
        ContentData: post.ContentData,
        ContentImage: post.ContentImage,
        InReplyTo: post.InReplyTo,
      });
      if (!response.success) {
        response.alert = true;
      }
      response.ImagePreload = post.ImagePreload;
      return response;
    } else {
      // mock message post for runner explore mode
      if (post.Category === "runner") {
        const timestamp = dayjs().toISOString();
        return {
          success: true,
          data: {
            Category: post.Category,
            EventId: post.EventId,
            RoomArea: post.RoomArea,
            RoomName: post.RoomName,
            UserId: post.UserId,
            ContentData: post.ContentData,
            ContentType: post.ContentType,
            FirstCreated: timestamp,
            LastUpdated: timestamp,
          },
        };
      } else {
        return {
          alert: true,
          message: "Please connect to post messages",
        };
      }
    }
  };
  const prepareRemoveMessage = async (post) => {
    let response;
    const userConnected = getUserConnected(post);
    if (userConnected) {
      response = await serviceGraphCall("mutation", "removeChatEventContent", {
        EventId: post.EventId,
        RoomName: post.RoomName,
        PostId: post.PostId,
      });
      if (!response.success) {
        response.alert = true;
      }
      return response;
    } else {
      return {
        alert: true,
        message: "Please connect to remove messages",
      };
    }
  };

  const preparePullMessageSettings = async (params) => {
    let response;
    const userConnected = getUserConnected(params);
    if (userConnected) {
      const roomName = params.RoomName ? params.RoomName : "main";
      const pushSetting = {
        SettingId: "alerts:push:room:" + params.EventId + ":" + roomName,
      };
      const mailSetting = {
        SettingId: "alerts:mail:room:" + params.EventId + ":" + roomName,
      };
      // pull both push and mail settings
      response = await Promise.all([
        prepareUserSetting(pushSetting),
        prepareUserSetting(mailSetting),
      ]);
      const alertMessage =
        "Hmm there was a problem loading your chat notification settings. Contact us if this persists.";
      if (!response[0].success || !response[1].success) {
        return {
          alert: true,
          message: alertMessage,
        };
      }
      const settings = {
        roomName: roomName,
        data: {
          push:
            response[0].data && response[0].data.SettingValue === "active"
              ? true
              : false,
          mail:
            response[1].data && response[1].data.SettingValue === "active"
              ? true
              : false,
        },
      };
      return settings;
    } else {
      // do not alert if not connected while user is exploring
      return {
        alert: false,
        message: "Please connect to manage chat settings",
      };
    }
  };
  const prepareUpdateMessageSettings = async (params) => {
    let response, payload;
    const userConnected = getUserConnected(params);
    if (userConnected) {
      const roomName = params.RoomName ? params.RoomName : "main";
      // prepare an interest payload for notification payload being changed
      payload = {
        SettingId:
          "alerts:" +
          params.Settings.changed +
          ":room:" +
          params.EventId +
          ":" +
          roomName,
        SettingValue: params.Settings[params.Settings.changed]
          ? "active"
          : "inactive",
      };
      // always delete first
      response = await prepareUserSetting({
        update: true,
        ...payload,
      });
      if (!response.success) {
        response.alert = true;
        return response;
      }
      return {
        roomName: roomName,
        data: params.Settings,
      };
    } else {
      return {
        alert: true,
        message: "Please connect to manage chat settings",
      };
    }
  };

  const prepareInviteLink = async (params) => {
    const userConnected = getUserConnected(params);
    if (userConnected) {
      const response = await serviceGraphCall("mutation", "generateJoinLink", {
        EventId: params.EventId,
        Category: params.Category,
      });
      if (!response.success) {
        response.alert = true;
        return response;
      }
      return response;
    } else {
      return {
        data: null,
      };
    }
  };
  const prepareFormatInviteLink = (params) => {
    let link = configEntzy.APP_URL;
    link += "/?action=join";
    link += "&token=";
    link += encodeURIComponent(params.Token);
    link += "&eid=";
    link += encodeURIComponent(params.EventId);
    return link;
  };

  const preparePullMembers = async (params) => {
    let response;
    const userConnected = getUserConnected(params);
    if (userConnected) {
      response = await serviceGraphCall("query", "viewMembers", {
        EventId: params.EventId,
        MemberType: params.MemberType,
        nextToken: params.nextToken,
        limit: configEntzy.PAGE_SIZE_USERS,
      });
      if (!response.success) {
        response.alert = true;
        return response;
      }
      if (params.MemberType.startsWith("invite")) {
        response.data.items = response.data.items.map((item) => {
          item.Link = prepareFormatInviteLink({
            Token: item.UserId,
            EventId: item.EventId,
          });
          item.Category = item.UserId.split(":")[1];
          return item;
        });
      }
      return response;
    } else {
      return {
        data: {
          items: [],
        },
      };
    }
  };
  const preparePullRunners = async (params) => {
    params.MemberType = "manager";
    return await preparePullMembers(params);
  };
  const preparePullRiders = async (params) => {
    params.MemberType = "listed";
    return await preparePullMembers(params);
  };
  const preparePullRunnerInvites = async (params) => {
    params.MemberType = "invite:manager";
    return await preparePullMembers(params);
  };
  const preparePullRiderInvites = async (params) => {
    params.MemberType = "invite:listed";
    return await preparePullMembers(params);
  };
  const preparePullRiderQueue = async (params) => {
    params.MemberType = "requested";
    return await preparePullMembers(params);
  };

  const prepareUpdateRunners = async (update) => {
    let response;
    const userConnected = getUserConnected(update);
    if (userConnected) {
      const userAccess = {
        EventId: state.event.data.EventId,
        Email: update.Email,
        Status: update.Status,
        Action: update.Action,
        Notify: "email",
      };
      response = await serviceGraphCall("mutation", "updateAccess", userAccess);
      if (!response.success) {
        response.alert = true;
        return response;
      }
      const results = response.data;
      return results;
    } else {
      return update;
    }
  };

  const prepareUpdateRiders = async (update) => {
    let response;
    const userConnected = getUserConnected(update);
    if (userConnected) {
      response = {};
      return response;
      // const userAccess = {
      //   EventId: state.event.data.EventId,
      //   Email: update.Email,
      //   Status: update.Status,
      //   Action: update.Action,
      //   Notify: "email",
      // };
      // response = await serviceGraphCall("mutation", "updateAccess", userAccess);
      // if (!response.success) {
      //   response.alert = true;
      //   return response;
      // }
      // const results = response.data;
      // return results;
    } else {
      return update;
    }
  };

  const preparePullLinks = async (params) => {
    let response;
    const userConnected = getUserConnected(params);
    const eventPublic = state.event.data.Access === "public";
    if (userConnected || (eventPublic && !params.exploring)) {
      response = await serviceGraphCall("query", "publicViewLinks", {
        EventId: params.EventId,
        LinkId: params.LinkId,
        LinkType: params.LinkType,
        nextToken: params.nextToken,
        limit: configEntzy.PAGE_SIZE_SHARING,
      });
      if (!response.success) {
        response.alert = true;
        return response;
      }
      const dataItems = params.more
        ? state.links.data.combined.concat(response.data.items)
        : response.data.items;
      // split links into runner and rider
      const eventLinks = {
        data: {
          combined: dataItems,
          runners: dataItems.filter((obj) => obj.Category === "runner"),
          riders: dataItems.filter((obj) => obj.Category === "rider"),
          nextToken: response.data.nextToken,
        },
      };
      // return event links
      return eventLinks;
    } else {
      return {
        data: {
          combined: [],
          runners: [],
          riders: [],
        },
      };
    }
  };
  const preparePostLink = async (params) => {
    let response;
    const userConnected = getUserConnected(params);
    if (userConnected) {
      response = await serviceGraphCall("mutation", "postLink", {
        EventId: params.EventId,
        LinkType: params.LinkType,
        LinkTarget: params.LinkTarget,
        Title: params.Title,
        Description: params.Description,
      });
      if (!response.success) {
        response.alert = true;
      }
      return response;
    } else {
      return {
        alert: true,
        message: "Please connect to post links",
      };
    }
  };
  const prepareRemoveLink = async (params) => {
    let response;
    const userConnected = getUserConnected(params);
    if (userConnected) {
      response = await serviceGraphCall("mutation", "deleteLink", {
        EventId: params.EventId,
        LinkId: params.LinkId,
      });
      if (!response.success) {
        response.alert = true;
      }
      return response;
    } else {
      return {
        alert: true,
        message: "Please connect to update links",
      };
    }
  };

  const preparePullViewerTickets = async (params) => {
    let response;
    const userConnected = cbGetUserConnected(params);
    if (userConnected) {
      response = await serviceGraphCall("query", "viewTickets", {
        EventId: params.EventId,
        nextToken: params.nextToken,
        limit: configEntzy.PAGE_SIZE_TICKETS,
      });
      if (!response.success) {
        response.alert = true;
        return response;
      }
      return {
        data: response.data.items,
      };
    } else {
      return {
        data: [],
      };
    }
  };
  const cbPreparePullViewerTickets = useCallback(preparePullViewerTickets, [
    cbGetUserConnected,
  ]);

  const preparePullViewerTicket = async (params) => {
    let response;
    const userConnected = cbGetUserConnected(params);
    if (userConnected) {
      response = await serviceGraphCall("query", "viewTicket", {
        EventId: params.EventId,
        TicketId: params.TicketId,
      });
      if (!response.success) {
        response.alert = true;
      }
      return response;
    } else {
      return {
        alert: true,
        message: "Please connect to view tickets",
      };
    }
  };

  const prepareTicketOffer = async (params) => {
    let response;
    const userConnected = getUserConnected(params);
    if (userConnected) {
      // validate: check for max offers
      if (
        state.viewer.tickets.offered.length >= state.constants.tickets.maxOffers
      ) {
        return {
          alert: true,
          message:
            "You have reached the maximum tickets possible to have on offer per event (" +
            state.constants.tickets.maxOffers +
            "). You can still edit your existing offers or wait for some to launch before adding more.",
        };
      }
      // validate: check that ticket type selection is the launched one if launch per ticket active
      if (state.event.data.LaunchPerTicket) {
        const ticketTypeValidations = [];
        for (let i = 0; i < params.DateList.length; i++) {
          let dateId,
            dateLaunched,
            ticketTypeBreakdown,
            ticketTypeMeta,
            ticketTypeNames;
          dateId = dayjs(state.constants.dates.base, "YYYY-MM-DD")
            .add(params.DateList[i], "days")
            .format("YYYY-MM-DD");
          dateLaunched = state.event.calendar.dates.launched.all.items.filter(
            (obj) => obj.DateKey === dateId
          );
          if (dateLaunched.length) {
            try {
              ticketTypeBreakdown = JSON.parse(dateLaunched[0].Breakdown);
              ticketTypeMeta = JSON.parse(dateLaunched[0].Meta);
            } catch (error) {
              ticketTypeBreakdown = {};
              ticketTypeMeta = { TicketTypes: [] };
            }
            if (
              ticketTypeBreakdown[params.TypeId] &&
              ticketTypeBreakdown[params.TypeId].Quantity > 0
            ) {
              // ticket type is valid
            } else {
              // create a comma separated list of ticket types based on name
              ticketTypeNames = ticketTypeMeta.TicketTypes.filter(
                (obj) => ticketTypeBreakdown[obj.id].Quantity > 0
              ).map((obj) => obj.name);
              ticketTypeValidations.push(
                dateId + " (accepts '" + ticketTypeNames + "')"
              );
            }
          }
        }
        if (ticketTypeValidations.length) {
          return {
            alert: true,
            message:
              "The ticket type selected does not match the required type for the following dates: " +
              ticketTypeValidations.join(", ") +
              ". These dates have already launched with specific ticket requirements and can be selected if you match the active ticket type shown.",
          };
        }
      }
      // continue with offer
      const ticketOrder = {
        EventId: state.event.data.EventId,
        TypeId: params.TypeId,
        Quantity: params.Quantity,
      };
      response = await serviceGraphCall(
        "mutation",
        "purchaseTicket",
        ticketOrder
      );
      if (!response.success) {
        response.alert = true;
        return response;
      }
      const ticket = response.data;
      // created any named ticket holdings
      for (let i = 0; i < params.Holdings.named.length; i++) {
        if (params.Holdings.named[i].total > 0) {
          // sleep briefly to allow for eventual consistency
          await new Promise((resolve) => setTimeout(resolve, 1000));
          // join the event for target user
          // ### not required as handled by holding creation ###
          // response = await serviceGraphCall("mutation", "joinEventEntry", {
          //   EventId: params.EventId,
          //   AcceptUserId: params.Holdings.named[i].id,
          // });
          // if (!response.success) {
          //   response.alert = true;
          //   return response;
          // }
          // create holding for each item name
          const ticketHolding = {
            EventId: state.event.data.EventId,
            TicketId: ticket.TicketId,
            ContactName: params.Holdings.named[i].name,
            Category: params.Holdings.sharePayment ? "billing" : "standard",
            Quantity: params.Holdings.named[i].total,
            Status: "initiated",
          };
          response = await serviceGraphCall(
            "mutation",
            "manageHolding",
            ticketHolding
          );
          if (!response.success) {
            response.alert = true;
            return response;
          }
        }
      }
      // finally tick the dates selected
      const datesOffer = {
        EventId: state.event.data.EventId,
        TicketId: ticket.TicketId,
        SettingBase: state.constants.dates.base,
        SettingAdd: params.DateList,
      };
      response = await serviceGraphCall("mutation", "tickDates", datesOffer);
      if (!response.success) {
        response.alert = true;
        return response;
      }
      // done return ticket
      return ticket;
    } else {
      // TODO: UNAUTHENTICATED ORDERING
      return {
        alert: true,
        message: "Please connect to place offers",
      };
    }
  };

  const prepareTicketUpdate = async (params) => {
    let response, payload, actionType, actionName;
    const userConnected = getUserConnected(params);
    if (userConnected) {
      switch (params.action) {
        case "retry-payment":
          actionType = "mutation";
          actionName = "rechargeTicket";
          payload = {
            EventId: params.EventId,
            TicketId: params.TicketId,
          };
          break;
        case "refund-ticket":
          actionType = "mutation";
          actionName = "triggerReversal";
          payload = {
            EventId: params.EventId,
            TicketId: params.TicketId,
            DateId: params.EntryDate,
          };
          break;
        case "refund-date":
          actionType = "mutation";
          actionName = "triggerReversal";
          payload = {
            EventId: params.EventId,
            DateId: params.EntryDate,
          };
          break;
        default:
          actionType = false;
      }
      response = actionType
        ? await serviceGraphCall(actionType, actionName, payload)
        : null;
      if (response) {
        if (!response.success || response.alert) {
          response.alert = true;
          return response;
        }
      }
    } else {
      return {
        alert: true,
        message: "Please connect to manage tickets",
      };
    }
  };

  const prepareEntryHandshake = async (params) => {
    let response;
    const userConnected = getUserConnected(params);
    if (userConnected) {
      // identify holder involved in the handshake
      let holder, holderUserId;
      // runners identify holder selection from guest list otherwise take from viewer info
      if (params.ticket.TicketUserId) {
        holderUserId = params.ticket.TicketUserId;
      } else {
        holder = params.ticket.Shareholders
          ? params.ticket.Shareholders.find((obj) => obj.UserViewer === true)
          : null;
        holderUserId = holder ? holder.UserId : user.identity;
      }
      // get latest ticket details
      const ticketView = {
        EventId: params.ticket.EventId,
        TicketId: params.ticket.TicketId,
      };
      response = await serviceGraphCall("query", "viewTicket", ticketView);
      if (!response.success) {
        response.alert = true;
        return response;
      }
      const ticketDetails = response.data;
      // perform the handshake
      const ticketPayload = {
        DateId: params.ticket.EntryDate
          ? params.ticket.EntryDate
          : params.ticket.TickedFinal,
        EventId: params.ticket.EventId,
        TicketId: params.ticket.TicketId,
        TicketUserId: holderUserId,
        TicketHash: params.runnerView ? "manager" : ticketDetails.PartHash,
        TicketAction: params.action,
      };
      response = await serviceGraphCall(
        "mutation",
        "entryHandshake",
        ticketPayload
      );
      if (!response.success) {
        response.alert = true;
        return response;
      }
      response.data.Shareholders = ticketDetails.Shareholders;
      return response;
    } else {
      return {
        alert: true,
        message: "Please connect to validate tickets",
      };
    }
  };

  const prepareTicketWithdraw = async (ticket) => {
    let response;
    const userConnected = getUserConnected(ticket);
    if (userConnected) {
      const ticketWithdrawal = {
        EventId: state.event.data.EventId,
        TicketId: ticket.TicketId,
      };
      response = await serviceGraphCall(
        "mutation",
        "withdrawTicket",
        ticketWithdrawal
      );
      if (!response.success) {
        response.alert = true;
        return response;
      }
      return ticket;
    } else {
      return {
        alert: true,
        message: "Please connect to manage tickets",
      };
    }
  };

  const prepareTicketUpdateHolding = async (ticket, accepted) => {
    let response;
    const userConnected = getUserConnected(ticket);
    if (userConnected) {
      const ticketWithdrawal = {
        EventId: state.event.data.EventId,
        TicketId: ticket.TicketId,
        Status: accepted ? "accepted" : "rejected",
      };
      response = await serviceGraphCall(
        "mutation",
        "collectHolding",
        ticketWithdrawal
      );
      if (!response.success) {
        response.alert = true;
        return response;
      }
      return ticket;
    } else {
      return {
        alert: true,
        message: "Please connect to manage tickets",
      };
    }
  };

  const prepareTickDates = async (ticket) => {
    let response;
    const userConnected = getUserConnected(ticket);
    if (userConnected) {
      const datesTicked = ticket.TickedDates.filter(
        (obj) => obj.subtag === state.user.identity
      )[0].add;
      if (datesTicked.length <= state.constants.dates.maxOffers) {
        const datesOffer = {
          EventId: state.event.data.EventId,
          TicketId: ticket.TicketId,
          SettingBase: state.constants.dates.base,
          SettingAdd: datesTicked,
        };
        // TODO: need to update tickDates to add a counter to rider summary (so unticks can be handled)
        response = await serviceGraphCall("mutation", "tickDates", datesOffer);
        if (!response.success) {
          response.alert = true;
          return response;
        }
        return ticket;
      } else {
        return {
          alert: true,
          message:
            "You have reached the maximum dates possible to offer per ticket (" +
            state.constants.dates.maxOffers +
            "). You can still edit your existing offers or wait for some to launch before adding more.",
        };
      }
    } else {
      // TODO: UNAUTHENTICATED TICKING
      return {
        alert: true,
        message: "Please connect to place offers",
      };
    }
  };

  const preparePullTicks = async (params) => {
    let response;
    const userConnected = cbGetUserConnected(params);
    if (userConnected) {
      response = await serviceGraphCall("query", "viewTicks", {
        EventId: params.EventId,
        DateKey: params.DateKey,
        nextToken: params.nextToken,
        limit: configEntzy.PAGE_SIZE_TICKS,
      });
      if (!response.success) {
        response.alert = true;
        return response;
      }
      const dataItems = params.more
        ? state.ticks.dates[params.DateKey].data.concat(response.data.items)
        : response.data.items;
      return {
        dateKey: params.DateKey,
        data: dataItems,
        nextToken: response.data.nextToken,
      };
    } else {
      return {
        data: [],
      };
    }
  };

  // only invoked when successful response from stripe
  const prepareUpdatePaymentMethod = async (update) => {
    let response, attributes;
    const userConnected = getUserConnected(update);
    if (userConnected) {
      // set and update user attributes
      attributes = {
        "custom:entzy_payment_set": "true",
      };
      response = await userUpdate(attributes);
      if (response.success) {
        update.user = response.data;
      } else {
        response.alert = true;
        return response;
      }
      // initiate a backend sync
      response = await serviceGraphCall("mutation", "syncUser", {
        Action: "profile",
      });
      if (!response.success) {
        response.alert = true;
        return response;
      }
      // return key value updated
      return update;
    } else {
      return {
        alert: true,
        message: "Connect to make updates to payment details",
      };
    }
  };

  const prepareEntryStageDate = async (params) => {
    let response;
    const userConnected = getUserConnected(params);
    if (userConnected) {
      if (state.event.manager) {
        // runner view: pull guest list
        // note: uses expiry for whether to retrieve from cache
        if (
          !params.refresh &&
          state.viewer.entry.lists[params.dateKey] &&
          dayjs().diff(
            state.viewer.entry.lists[params.dateKey].expires,
            "minutes"
          ) < 0
        ) {
          response = {
            success: true,
            dateKey: params.dateKey,
            data: { items: state.viewer.entry.lists[params.dateKey].data },
          };
        } else {
          response = await serviceGraphCall("query", "viewDateList", {
            EventId: state.event.data.EventId,
            DateKey: params.dateKey,
            nextToken: params.nextToken,
            limit: configEntzy.PAGE_SIZE_ENTRY,
          });
        }
        if (!response.success) {
          response.alert = true;
          return response;
        }
        return {
          dateKey: params.dateKey,
          data: response.data.items.filter((obj) =>
            EventTicketLaunchStates().includes(obj.TickedStatus)
          ),
        };
      } else {
        // rider view: return date selection and empty guest list
        return {
          dateKey: params.dateKey,
          data: [],
        };
      }
    } else {
      return {
        alert: true,
        message: "Please connect to manage entry",
      };
    }
  };

  const preparePullPinnedStatus = async (params) => {
    const userConnected = getUserConnected(params);
    if (userConnected) {
      const payload = {
        EventId: params.EventId,
        Category: "events",
        SubCategory: params.owner
          ? "owner"
          : params.manager
          ? "manager"
          : "pinned",
      };
      const response = await serviceGraphCall("query", "getInterest", payload);
      if (!response.success) {
        response.alert = true;
        return response;
      }
      const isPinned = response.data && response.data.InterestId ? true : false;
      return isPinned;
    } else {
      return false;
    }
  };

  const preparePinEvent = async (params) => {
    const userConnected = getUserConnected(params);
    if (userConnected) {
      const actionMethod = params.pinned ? "joinEventEntry" : "joinEventExit";
      const actionPayload = {
        EventId: params.EventId,
      };
      if (params.userRemoval) {
        actionPayload.RemoveUserId = params.userRemoval.id;
        actionPayload.RemoveManagerAccessOnly = params.userRemoval.manager;
      }
      if (params.userAccept) {
        actionPayload.AcceptUserId = params.userAccept.id;
      }
      const response = await serviceGraphCall(
        "mutation",
        actionMethod,
        actionPayload
      );
      if (!response.success) {
        response.alert = true;
        return response;
      }
      const userId = user.identity;
      const isOwner = response.data === "owner";
      const isManager =
        response.data === "owner" || response.data === "manager";
      return {
        EventId: params.EventId,
        UserId: userId,
        owner: isOwner,
        manager: isManager,
        pinned: params.pinned,
      };
    } else {
      return {
        alert: true,
        message: "Please connect to manage interests",
      };
    }
  };

  const prepareInterest = async (params) => {
    let response, actionType, actionMethod;
    const userConnected = getUserConnected(params);
    if (userConnected) {
      if (params.delete) {
        actionType = "mutation";
        actionMethod = "deleteInterest";
      } else if (params.create) {
        actionType = "mutation";
        actionMethod = "createInterest";
      } else {
        actionType = "query";
        actionMethod = "getInterest";
      }
      response = await serviceGraphCall(actionType, actionMethod, {
        Category: params.Category, // events | tickets | rooms
        SubCategory: params.SubCategory,
        EventId: params.EventId, // must provide one of following three
        TicketId: params.TicketId,
        RoomId: params.RoomId,
        ActivityId: params.ActivityId,
      });
      if (!response.success) {
        response.alert = true;
        return response;
      }
      return response;
    } else {
      return {
        alert: true,
        message: "Please connect to manage interests",
      };
    }
  };

  const prepareUserSetting = async (params) => {
    const userConnected = getUserConnected(params);
    if (userConnected) {
      return serviceUserSetting(params);
    } else {
      return {
        alert: true,
        message: "Please connect to manage settings",
      };
    }
  };

  // wrapper function for event startup after base event data initialised
  const prepareStartEvent = async (eventData) => {
    const promiseUser = new Promise((resolve, reject) => {
      (async () => {
        const response = await userGet();
        return resolve({ name: "user", response });
      })();
    });
    const dataList = await Promise.all([promiseUser]);
    const results = {
      alert: false,
      message: "",
    };
    for (const result of dataList) {
      results[result.name] = result.response;
      if (result.response.alert) {
        results.alert = true;
        results.message +=
          "Currently unable to retrieve latest data for " + result.name + ". ";
      }
    }
    return results;
  };
  const startDispatchList = (eventContent, startData, startType) => {
    const list = [];
    list.push({ type: eventActions.UPDATE_USER, user: startData.user });
    if (startType === "create") {
      list.push({ type: eventActions.CREATE_EVENT, params: eventContent.data });
    } else {
      list.push({ type: eventActions.JOIN_EVENT, content: eventContent });
    }
    list.push({ type: eventActions.GENERATE_CALENDAR });
    list.push({ type: eventActions.GENERATE_MODULES });
    if (startData.alert) {
      list.push({
        type: eventActions.UPDATE_ALERT,
        alert: { show: true, message: startData.message },
      });
    }
    return list;
  };

  // wrapper function for any background activity to happen after event startup
  const preparePostStartEvent = async (eventContent) => {
    const userConnected = getUserConnected(eventContent);
    const promiseRunners = new Promise((resolve, reject) => {
      (async () => {
        const response = await preparePullRunners({
          EventId: eventContent.data.EventId,
          exploring: eventContent.exploring,
        });
        return resolve({ name: "runners", response });
      })();
    });
    const promiseRiders = new Promise((resolve, reject) => {
      (async () => {
        const response = await preparePullRiders({
          EventId: eventContent.data.EventId,
          exploring: eventContent.exploring,
        });
        return resolve({ name: "riders", response });
      })();
    });
    const promiseRunnerInvites = new Promise((resolve, reject) => {
      (async () => {
        const response = await preparePullRunnerInvites({
          EventId: eventContent.data.EventId,
          exploring: eventContent.exploring,
        });
        return resolve({ name: "runnerInvites", response });
      })();
    });
    const promiseRiderInvites = new Promise((resolve, reject) => {
      (async () => {
        const response = await preparePullRiderInvites({
          EventId: eventContent.data.EventId,
          exploring: eventContent.exploring,
        });
        return resolve({ name: "riderInvites", response });
      })();
    });
    const promiseRiderQueue = new Promise((resolve, reject) => {
      (async () => {
        const response = await preparePullRiderQueue({
          EventId: eventContent.data.EventId,
          exploring: eventContent.exploring,
        });
        return resolve({ name: "riderQueue", response });
      })();
    });
    const promiseLinks = new Promise((resolve, reject) => {
      (async () => {
        const response = await preparePullLinks({
          EventId: eventContent.data.EventId,
          LinkType: "url",
          exploring: eventContent.exploring,
        });
        return resolve({ name: "links", response });
      })();
    });
    const promisePlaces = new Promise((resolve, reject) => {
      (async () => {
        const response = await preparePullLinks({
          EventId: eventContent.data.EventId,
          LinkType: "place",
          exploring: eventContent.exploring,
        });
        return resolve({ name: "places", response });
      })();
    });
    const promiseViewerTickets = new Promise((resolve, reject) => {
      (async () => {
        const response = await preparePullViewerTickets({
          EventId: eventContent.data.EventId,
          exploring: eventContent.exploring,
        });
        return resolve({ name: "viewerTickets", response });
      })();
    });
    const promisePinnedStatus = new Promise((resolve, reject) => {
      (async () => {
        const response = await preparePullPinnedStatus({
          EventId: eventContent.data.EventId,
          owner: eventContent.owner,
          manager: eventContent.manager,
          exploring: eventContent.exploring,
        });
        return resolve({ name: "pinnedStatus", response });
      })();
    });
    // activate all message rooms and settings
    const promiseMessagesRooms = [];
    const promiseMessageSettingsRooms = [];
    if (userConnected) {
      for (const room of EVENT_CONSTANTS.messaging.rooms) {
        if (room.name.includes("runner") && !eventContent.manager) continue; // skip runner rooms if not manager
        if (room.name.includes("rider") && eventContent.manager) continue; // skip rider rooms if manager
        promiseMessagesRooms.push(
          new Promise((resolve, reject) => {
            (async () => {
              const response = await preparePullMessages({
                EventId: eventContent.data.EventId,
                RoomName: room.name,
              });
              return resolve({ name: room.name + "Messages", response });
            })();
          })
        );
        promiseMessageSettingsRooms.push(
          new Promise((resolve, reject) => {
            (async () => {
              const response = await preparePullMessageSettings({
                EventId: eventContent.data.EventId,
                RoomName: room.name,
              });
              return resolve({ name: room.name + "MessageSettings", response });
            })();
          })
        );
      }
    }
    const dataList = await Promise.all([
      promiseRunners,
      promiseRiders,
      promiseRunnerInvites,
      promiseRiderInvites,
      promiseRiderQueue,
      promiseLinks,
      promisePlaces,
      promiseViewerTickets,
      promisePinnedStatus,
      ...promiseMessagesRooms,
      ...promiseMessageSettingsRooms,
    ]);
    const results = {
      alert: false,
      message: "",
    };
    for (const result of dataList) {
      results[result.name] = result.response;
      if (result.response.alert) {
        results.alert = true;
        results.message +=
          "Currently unable to retrieve latest data for " + result.name + ". ";
      }
    }
    return results;
  };
  const postStartDispatchList = (postStartData) => {
    const list = [];
    if (!postStartData.runners.alert) {
      list.push({
        type: eventActions.PULL_RUNNERS,
        runners: postStartData.runners,
      });
    }
    if (!postStartData.riders.alert) {
      list.push({
        type: eventActions.PULL_RIDERS,
        riders: postStartData.riders,
      });
    }
    if (!postStartData.runnerInvites.alert) {
      list.push({
        type: eventActions.PULL_RUNNER_INVITES,
        runnerInvites: postStartData.runnerInvites,
      });
    }
    if (!postStartData.riderInvites.alert) {
      list.push({
        type: eventActions.PULL_RIDER_INVITES,
        riderInvites: postStartData.riderInvites,
      });
    }
    if (!postStartData.riderQueue.alert) {
      list.push({
        type: eventActions.PULL_RIDER_QUEUE,
        riderQueue: postStartData.riderQueue,
      });
    }
    if (!postStartData.links.alert) {
      list.push({
        type: eventActions.PULL_LINKS,
        content: postStartData.links,
      });
    }
    if (!postStartData.places.alert) {
      list.push({
        type: eventActions.PULL_PLACES,
        content: postStartData.places,
      });
    }
    if (!postStartData.viewerTickets.alert) {
      list.push({
        type: eventActions.PULL_VIEWER_TICKETS,
        tickets: postStartData.viewerTickets,
      });
    }
    if (!postStartData.pinnedStatus.alert) {
      list.push({
        type: eventActions.SET_PINNED_STATUS,
        status: postStartData.pinnedStatus,
      });
    }
    // dispatches for messaging rooms and settings
    for (const room of EVENT_CONSTANTS.messaging.rooms) {
      if (
        postStartData[room.name + "Messages"] &&
        !postStartData[room.name + "Messages"].alert
      ) {
        list.push({
          type: eventActions.PULL_MESSAGES,
          content: postStartData[room.name + "Messages"],
        });
      }
      if (
        postStartData[room.name + "MessageSettings"] &&
        !postStartData[room.name + "MessageSettings"].alert
      ) {
        list.push({
          type: eventActions.PULL_MESSAGE_SETTINGS,
          settings: postStartData[room.name + "MessageSettings"],
        });
      }
    }
    if (postStartData.alert) {
      list.push({
        type: eventActions.UPDATE_ALERT,
        alert: { show: true, message: postStartData.message },
      });
    } else {
      list.push({ type: eventActions.GENERATE_MODULES });
    }
    return list;
  };

  // context value to pass down
  const value = {
    state,
    focusModule: (selection, landing) => {
      const element = document.getElementById("anchor-event-runner");
      if (element) {
        scrollIntoViewIfNeeded(element, false);
      }
      selection = selection.replace("menu", "launchpad"); // remove if reactivate dedicated menu
      dispatch({ type: eventActions.FOCUS_MODULE, selection, landing });
    },
    prepareCreateEvent: prepareCreateEvent,
    createEvent: (params) => {
      dispatch({ type: eventActions.CREATE_EVENT, params });
    },
    prepareJoinEvent: prepareJoinEvent,
    joinEvent: (content) => {
      dispatch({ type: eventActions.JOIN_EVENT, content });
    },
    prepareRequestJoinEvent: prepareRequestJoinEvent,
    requestJoinEvent: (request) => {
      dispatch({ type: eventActions.REQUEST_JOIN_EVENT, request });
    },
    prepareStartEvent: prepareStartEvent,
    startEvent: (eventContent, startData, startType) => {
      const dispatchList = startDispatchList(
        eventContent,
        startData,
        startType
      );
      dispatchList.forEach((dispatchItem) => {
        dispatch(dispatchItem);
      });
      // kick off background activity after event started if user has access
      if (eventContent.accessGranted) {
        setTimeout(() => {
          preparePostStartEvent(eventContent).then((results) => {
            const dispatchList = postStartDispatchList(results);
            dispatchList.forEach((dispatchItem) => {
              dispatch(dispatchItem);
            });
          });
        }, 1000);
      }
    },
    toggleModule: (module) => {
      dispatch({ type: eventActions.TOGGLE_MODULE, module });
    },
    prepareUpdateEvent: prepareUpdateEvent,
    updateEvent: (field) => {
      if (field.multiple) {
        field.keysets.forEach((field) => {
          dispatch({ type: eventActions.UPDATE_EVENT, field });
        });
      } else {
        dispatch({ type: eventActions.UPDATE_EVENT, field });
      }
      dispatch({ type: eventActions.GENERATE_CALENDAR });
      dispatch({ type: eventActions.GENERATE_MODULES });
    },
    updateEventStamp: (field) => {
      dispatch({ type: eventActions.UPDATE_EVENT_STAMP, field });
    },
    updateAlert: (params) => {
      dispatch({
        type: eventActions.UPDATE_ALERT,
        alert: params
          ? {
              show: params.show || params.alert ? true : false,
              message: params.message,
            }
          : { show: false },
      });
    },
    generateCalendar: () => {
      dispatch({ type: eventActions.GENERATE_CALENDAR });
      dispatch({ type: eventActions.GENERATE_MODULES });
    },
    generateModules: () => {
      dispatch({ type: eventActions.GENERATE_MODULES });
    },
    preparePostSocials: preparePostSocials,
    postSocials: (social) => {
      dispatch({ type: eventActions.POST_SOCIALS, social });
    },
    preparePullRunners: preparePullRunners,
    pullRunners: (runners) => {
      dispatch({ type: eventActions.PULL_RUNNERS, runners });
    },
    preparePullRiders: preparePullRiders,
    pullRiders: (riders) => {
      dispatch({ type: eventActions.PULL_RIDERS, riders });
    },
    prepareInviteLink: prepareInviteLink,
    prepareFormatInviteLink: prepareFormatInviteLink,
    preparePullRunnerInvites: preparePullRunnerInvites,
    pullRunnerInvites: (runnerInvites) => {
      dispatch({ type: eventActions.PULL_RUNNER_INVITES, runnerInvites });
    },
    preparePullRiderInvites: preparePullRiderInvites,
    pullRiderInvites: (riderInvites) => {
      dispatch({ type: eventActions.PULL_RIDER_INVITES, riderInvites });
    },
    preparePullRiderQueue: preparePullRiderQueue,
    pullRiderQueue: (riderQueue) => {
      dispatch({ type: eventActions.PULL_RIDER_QUEUE, riderQueue });
    },
    prepareUpdateRunners: prepareUpdateRunners,
    updateRunners: (user) => {
      dispatch({ type: eventActions.UPDATE_RUNNERS, user });
    },
    prepareUpdateRiders: prepareUpdateRiders,
    updateRiders: (user) => {
      dispatch({ type: eventActions.UPDATE_RIDERS, user });
    },
    updateRunnerInvites: (user) => {
      dispatch({ type: eventActions.UPDATE_RUNNER_INVITES, user });
    },
    updateRiderInvites: (user) => {
      dispatch({ type: eventActions.UPDATE_RIDER_INVITES, user });
    },
    preparePullLinks: preparePullLinks,
    pullLinks: (content) => {
      dispatch({ type: eventActions.PULL_LINKS, content });
    },
    preparePostLink: preparePostLink,
    prepareRemoveLink: prepareRemoveLink,
    postLink: (link) => {
      dispatch({ type: eventActions.POST_LINK, link });
      dispatch({ type: eventActions.GENERATE_MODULES });
    },
    removeLink: (link) => {
      dispatch({ type: eventActions.REMOVE_LINK, link });
    },
    pullPlaces: (content) => {
      dispatch({ type: eventActions.PULL_PLACES, content });
    },
    prepareUpdatePlaces: prepareUpdatePlaces,
    updatePlaces: (places) => {
      dispatch({ type: eventActions.UPDATE_PLACES, places });
      dispatch({ type: eventActions.GENERATE_CALENDAR });
      dispatch({ type: eventActions.GENERATE_MODULES });
    },
    preparePullMessages: preparePullMessages,
    pullMessages: (content) => {
      dispatch({ type: eventActions.PULL_MESSAGES, content });
      dispatch({ type: eventActions.GENERATE_MODULES });
    },
    preparePullMessageSettings: preparePullMessageSettings,
    pullMessageSettings: (settings) => {
      dispatch({ type: eventActions.PULL_MESSAGE_SETTINGS, settings });
    },
    prepareUpdateMessageSettings: prepareUpdateMessageSettings,
    updateMessageSettings: (settings) => {
      dispatch({ type: eventActions.UPDATE_MESSAGE_SETTINGS, settings });
    },
    setMessageRoom: (roomName) => {
      dispatch({ type: eventActions.SET_MESSAGE_ROOM, roomName });
      dispatch({ type: eventActions.GENERATE_MODULES });
    },
    preparePostMessage: preparePostMessage,
    postMessage: (message) => {
      dispatch({ type: eventActions.POST_MESSAGE, message });
    },
    prepareRemoveMessage: prepareRemoveMessage,
    removeMessage: (message) => {
      dispatch({ type: eventActions.REMOVE_MESSAGE, message });
    },
    preparePullViewerTickets: preparePullViewerTickets,
    pullViewerTickets: (tickets) => {
      dispatch({ type: eventActions.PULL_VIEWER_TICKETS, tickets });
    },
    preparePullViewerTicket: preparePullViewerTicket,
    prepareUpdatePaymentMethod: prepareUpdatePaymentMethod,
    updatePaymentMethod: (details) => {
      dispatch({ type: eventActions.UPDATE_USER, user: details.user });
    },
    prepareTicketOffer: prepareTicketOffer,
    prepareTicketWithdraw: prepareTicketWithdraw,
    prepareTicketUpdate: prepareTicketUpdate,
    prepareTicketUpdateHolding: prepareTicketUpdateHolding,
    prepareTickDates: prepareTickDates,
    preparePullTicks: preparePullTicks,
    pullTicks: (ticks) => {
      dispatch({ type: eventActions.PULL_TICKS, ticks });
    },
    updateViewerTickets: (ticket) => {
      dispatch({ type: eventActions.UPDATE_VIEWER_TICKETS, ticket });
    },
    prepareEntryStageDate: prepareEntryStageDate,
    entryStageDate: (list) => {
      dispatch({ type: eventActions.ENTRY_STAGE_DATE, list });
      dispatch({ type: eventActions.GENERATE_MODULES });
    },
    prepareEntryHandshake: prepareEntryHandshake,
    entryHandshake: (handshake) => {
      dispatch({ type: eventActions.ENTRY_HANDSHAKE, handshake });
    },
    preparePinEvent: preparePinEvent,
    pinEvent: (joiner) => {
      dispatch({ type: eventActions.SET_PINNED_STATUS, status: joiner.pinned });
      const joinerUser = {
        EventId: joiner.EventId,
        UserId: joiner.UserId,
        remove: joiner.pinned ? undefined : true,
      };
      dispatch({
        type: eventActions.UPDATE_MESSAGE_SETTINGS,
        settings: {
          roomName: "main",
          data: {
            mail: joiner.pinned,
            push: joiner.pinned,
            changed: joiner.pinned ? "pinned" : "unpinned",
          },
        },
      });
      if (joiner.owner) {
        // no action needed to members lists for owners
      } else if (joiner.manager) {
        joinerUser.GroupStatus = "manager:" + joiner.UserId;
        dispatch({ type: eventActions.UPDATE_RUNNERS, user: joinerUser });
      } else {
        joinerUser.GroupStatus = "listed:" + joiner.UserId;
        dispatch({ type: eventActions.UPDATE_RIDERS, user: joinerUser });
      }
    },
    prepareInterest: prepareInterest,
    prepareUserSetting: prepareUserSetting,
    hangImageFrames: hangImageFrames,
    menuSelectById: (id) => {
      props.menuSelectById(id);
    },
    setHydrated: (hydrated) => {
      dispatch({ type: eventActions.SET_HYDRATED, hydrated });
    },
    setLoading: (loading, loadingMore) => {
      dispatch({ type: eventActions.SET_LOADING, loading, loadingMore });
    },
    setCallbackLoader: (callbackLoader, callbackState) => {
      dispatch({
        type: eventActions.SET_CALLBACK_LOADER,
        callbackLoader,
        callbackState,
      });
    },
    setObservablesTracker: (observablesTracker) => {
      dispatch({
        type: eventActions.SET_OBSERVABLES_TRACKER,
        observablesTracker,
      });
    },
    setMemberDrawer: (drawer) => {
      dispatch({ type: eventActions.SET_MEMBER_DRAWER, drawer });
    },
    setActionDrawer: (drawer) => {
      dispatch({ type: eventActions.SET_ACTION_DRAWER, drawer });
    },
    toggleConnect: () => {
      props.drawerConnectToggle();
    },
    exitEvent: (params) => {
      dispatch({ type: eventActions.EXIT_EVENT, params });
      closeAction(
        params.mainContext,
        params.eventContext,
        "home",
        props.menuSelectById
      );
    },
  };

  // subscription openers and callbacks
  useEffect(() => {
    const callbackAction = async (error, observable, result) => {
      dispatch({
        type: eventActions.SET_CALLBACK_LOADER,
        callbackLoader: false,
      });
      if (error) {
        return errorAction(error, observable, result);
      } else {
        const payload = {
          type: observable.action.type,
        };
        payload[observable.action.property] = result;
        dispatch(payload);
        if (observable.action.recalculate) {
          dispatch({ type: eventActions.GENERATE_MODULES });
        }
        if (observable.action.recalendar) {
          dispatch({
            type: eventActions.GENERATE_CALENDAR,
          });
        }
        if (observable.action.reticket) {
          const viewerTickets = await cbPreparePullViewerTickets({
            EventId: state.event.data.EventId,
            user: state.user,
          });
          if (viewerTickets.alert) {
            dispatch({
              type: eventActions.UPDATE_ALERT,
              alert: { show: true, message: viewerTickets.message },
            });
          } else {
            dispatch({
              type: eventActions.PULL_VIEWER_TICKETS,
              tickets: viewerTickets,
            });
          }
        }
      }
    };
    const errorAction = async (error, observable, result) => {
      console.error(
        "Warning from eventuator observable: refresh for latest data"
      );
      // dispatch({
      //   type: eventActions.UPDATE_ALERT,
      //   alert: {
      //     show: true,
      //     message: "Refresh for latest eventuator data",
      //   },
      // });
      // serviceLogError("observableCallback", { error, observable, result });
    };
    const openSubscription = async (type) => {
      let response = {
        success: false,
        data: null,
      };
      // set custom params
      const customParams = eventObservables[type].params;
      // any override conditions to restart subscription
      if (type === "messaging") {
        if (state.messaging.activeRoom !== customParams.RoomName) {
          customParams.RoomName = state.messaging.activeRoom;
          if (customParams.RoomName === "desk" && !state.event.manager) {
            customParams.RoomName = "desk:" + state.user.identity;
          }
          closeSubscription(type);
        }
      }
      // first check and close if not required for this module
      if (
        !eventObservables[type].modules.includes(state.event.moduleSelected) &&
        eventObservables[type].active
      ) {
        closeSubscription(type);
      }
      // open if required for this module
      if (
        eventObservables[type].modules.includes(state.event.moduleSelected) &&
        eventObservables[type].field &&
        eventObservables[type].action &&
        !eventObservables[type].active
      ) {
        // set base subscription params
        const callParams = {
          Url: state.event.data.Url,
          EventId: state.event.data.EventId,
          ...customParams,
        };
        response = await serviceGraphCall(
          "subscription",
          eventObservables[type].field,
          callParams,
          {
            observable: eventObservables[type],
            call: callbackAction,
          }
        );
        eventObservables[type].active = response.success;
        eventObservables[type].subscription = response.success
          ? response.data
          : null;
        if (!response.success) {
          return errorAction(
            response.message,
            eventObservables[type],
            response
          );
        }
      }
      return response.data;
    };
    const closeSubscription = async (type) => {
      if (eventObservables[type].active) {
        eventObservables[type].subscription.unsubscribe();
        eventObservables[type].active = false;
      }
      return eventObservables.event;
    };

    const openAllSubscriptions = async () => {
      // console.debug("Opening all subcriptions");
      eventObservables.list.forEach((type) => {
        openSubscription(type);
      });
      eventObservables.active = true;
      dispatch({
        type: eventActions.SET_OBSERVABLES_TRACKER,
        observablesTracker: { active: true },
      });
    };
    const closeAllSubscriptions = async () => {
      // console.debug("Closing all subcriptions");
      eventObservables.list.forEach((type) => {
        closeSubscription(type);
      });
      eventObservables.active = false;
      dispatch({
        type: eventActions.SET_OBSERVABLES_TRACKER,
        observablesTracker: { active: false },
      });
    };
    const startSubscriptions = async (name) => {
      openAllSubscriptions();
    };
    if (state.event.hydrated && state.user && state.user.connected) {
      if (pause) {
        // pause subscriptions when idle
        closeAllSubscriptions();
      } else {
        // open subscriptions when required
        startSubscriptions();
      }
    } else {
      if (eventObservables.active) {
        // cleanup on exit event
        // console.debug("Unmount exit event closing all subcriptions");
        closeAllSubscriptions();
      }
    }
  }, [
    state.event.hydrated,
    state.event.data.Url,
    state.event.data.EventId,
    state.event.moduleSelected,
    state.messaging.activeRoom,
    state.event.manager,
    state.user,
    pause,
    cbPreparePullViewerTickets,
  ]);

  // effect to monitor callbackState for any activity post callbackLoader
  useEffect(() => {
    if (state.callbackState && !state.callbackLoader) {
      switch (state.callbackState.type) {
        case "actionDrawer":
          // dispatch({
          //   type: eventActions.SET_ACTION_DRAWER,
          //   drawer: {
          //     open: true,
          //     ...state.callbackState.payload,
          //   },
          // });
          break;
        default:
          break;
      }
      dispatch({
        type: eventActions.SET_CALLBACK_LOADER,
        callbackLoader: false,
        callbackState: null,
      });
    }
  }, [state.callbackLoader, state.callbackState]);

  // subscription fallback cleanup on unmount of overall event context
  useEffect(() => {
    return () => {
      // console.debug("Unmount context closing all subcriptions");
      eventObservables.list.forEach((type) => {
        if (eventObservables[type].active) {
          eventObservables[type].subscription.unsubscribe();
        }
        eventObservables[type].active = false;
      });
    };
  }, []);

  // console.log("[EVENT CONTEXT]", state);
  // console.log("[EVENT OBSERVABLES]", eventObservables);

  return (
    <IdleTimerProvider
      timeout={1000 * 60 * configEntzy.IDLE_TIMEOUT_MINS}
      onPrompt={onPrompt}
      onIdle={onIdle}
      onActive={onActive}
      onAction={onAction}
    >
      <EventContext.Provider value={value}>
        {props.page === "/events/runner" ? (
          <EventRunner {...props} />
        ) : props.page === "/calendar" ? (
          <EventCalendar {...props} />
        ) : props.page === "/eventuators" ? (
          <EventList {...props} />
        ) : (
          <Fragment>
            {user.connected && <MenuStart {...props} />}
            {["/events", "/eventuators"].includes(props.page) && (
              <Box
                className="box-default"
                sx={{ p: configEntzy.APP_SPACING_MD }}
              >
                <Container maxWidth="lg">
                  <EventList {...props} />
                </Container>
              </Box>
            )}
          </Fragment>
        )}
      </EventContext.Provider>
    </IdleTimerProvider>
  );
}

export default EventIndex;

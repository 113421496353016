import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainApp from "components/main/MainApp";

function MainRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainApp page="/" />} />
        <Route path="/connecting" element={<MainApp page="/connecting" />} />
        <Route path="/menu" element={<MainApp page="/menu" />} />
        <Route path="/connect" element={<MainApp page="/connect" />} />
        <Route path="/health" element={<MainApp page="/health" />} />
        <Route path="/about" element={<MainApp page="/about" />} />
        <Route
          path="/features"
          element={<MainApp page="/contact/features" />}
        />
        <Route path="/support" element={<MainApp page="/contact/support" />} />
        <Route path="/contact" element={<MainApp page="/contact/support" />} />
        {/* <Route path="/events" element={<MainApp page="/events" />} /> */}
        <Route path="/eventuators" element={<MainApp page="/eventuators" />} />
        <Route path="/contacts" element={<MainApp page="/contacts" />} />
        <Route path="/settings" element={<MainApp page="/settings" />} />
        <Route
          path="/settings/profile"
          element={<MainApp page="/settings/profile" />}
        />
        <Route
          path="/settings/profile/name"
          element={<MainApp page="/settings/profile/name" />}
        />
        <Route
          path="/settings/profile/avatar"
          element={<MainApp page="/settings/profile/avatar" />}
        />
        <Route
          path="/settings/payment"
          element={<MainApp page="/settings/payment" />}
        />
        <Route
          path="/settings/payment/methods"
          element={<MainApp page="/settings/payment/methods" />}
        />
        <Route
          path="/settings/payment/history"
          element={<MainApp page="/settings/payment/history" />}
        />
        <Route
          path="/settings/payment/purchases"
          element={<MainApp page="/settings/payment/purchases" />}
        />
        <Route
          path="/settings/income"
          element={<MainApp page="/settings/income" />}
        />
        <Route
          path="/settings/income/bank"
          element={<MainApp page="/settings/income/bank" />}
        />
        <Route
          path="/settings/income/balance"
          element={<MainApp page="/settings/income/balance" />}
        />
        <Route
          path="/settings/transaction/history"
          element={<MainApp page="/settings/transaction/history" hideMenu />}
        />
        <Route
          path="/settings/security"
          element={<MainApp page="/settings/security" />}
        />
        <Route
          path="/settings/security/email"
          element={<MainApp page="/settings/security/email" />}
        />
        <Route
          path="/settings/security/password"
          element={<MainApp page="/settings/security/password" />}
        />
        <Route
          path="/settings/security/mfa"
          element={<MainApp page="/settings/security/mfa" />}
        />
        <Route
          path="/settings/account"
          element={<MainApp page="/settings/account" />}
        />
        <Route
          path="/settings/account/close"
          element={<MainApp page="/settings/account/close" />}
        />
        <Route path="/legal" element={<MainApp page="/legal" />} />
        <Route
          path="/legal/privacy"
          element={<MainApp page="/legal/privacy" />}
        />
        <Route path="/legal/terms" element={<MainApp page="/legal/terms" />} />
        {/* <Route path="/=/*" element={<MainApp page="/events/runner" />} />
        <Route
          path={"/" + String.fromCharCode(9869) + "/*"}
          element={<MainApp page="/events/runner" />}
        />
        <Route path="/⚍/*" element={<MainApp page="/events/runner" />} /> */}
        <Route
          path="/e/:eurl/:esection"
          element={<MainApp page="/events/runner" />}
        />
        <Route path="/e/:eurl" element={<MainApp page="/events/runner" />} />
        <Route
          path="/settings/profile/notes"
          element={<MainApp page="/members/me" />}
        />
        <Route path="/me" element={<MainApp page="/members" />} />
        <Route path="/me/:member" element={<MainApp page="/members" />} />
        <Route path="/members" element={<MainApp page="/members" />} />
        <Route path="/members/:member" element={<MainApp page="/members" />} />
        <Route path="/@" element={<MainApp page="/members" />} />
        <Route path="/@/:member" element={<MainApp page="/members" />} />
        <Route path="/pay" element={<MainApp page="/pay" />} />
        <Route path="/pay/:recipient" element={<MainApp page="/pay" />} />
        <Route path="/pay/e/:recipient" element={<MainApp page="/pay/e" />} />
        <Route path="/pay/@/:recipient" element={<MainApp page="/pay/m" />} />
        <Route
          path="/pay/members/:recipient"
          element={<MainApp page="/pay/m" />}
        />
        <Route path="*" element={<MainApp page="/404" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default MainRoutes;

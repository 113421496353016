// react core
import { useContext, useState } from "react";

// material design
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";

// entzy resources
import configEntzy from "components/config/ConfigEntzy";
import { MainContext } from "components/main/MainContext";
import { EventContext } from "pages/events/EventContext";
import {
  ActionBoxButton,
  CopyButton,
} from "components/utils/common/CommonButtons";
import {
  ActionLoader,
  ActionCheck,
} from "components/utils/common/CommonLoaders";
import { ActiveTag } from "components/utils/common/CommonTags";
import { jsonTryParse } from "models/Tools";

// fonts and icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle as iconInfo } from "@fortawesome/pro-duotone-svg-icons";

const steps = ["Post for Free", "Boost with Ads"];

function ActionLaunchpadRunnerSocials(props) {
  const user = props.user;
  const section = "members";
  const anchorTop = "anchor-launchpad-edit-" + section + "-top";

  const mainContext = useContext(MainContext);
  const eventContext = useContext(EventContext);

  const [loading, setLoading] = useState(false);
  const [dialog, setDialog] = useState({
    visible: false,
  });

  const handleConfirmActionSocial = async (event, confirm, social, params) => {
    if (confirm === true) {
      setDialog({ visible: false });
      await handleActionSocial(social, params);
    } else if (confirm === false) {
      setDialog({ visible: false });
    } else {
      setDialog({
        visible: true,
        title: params.delete
          ? "Confirm Delete Post"
          : params.repost
          ? "Confirm Repost"
          : params.boost
          ? "Confirm Boost"
          : "Confirm Action",
        description: params.delete
          ? "Are you sure you want to delete this post? You can only post or repost your eventuator once every " +
            configEntzy.SOCIALS.POST_LIMIT_DAYS +
            " days."
          : params.repost
          ? "Are you sure you want to delete and repost your eventuator? You can only post or repost once every " +
            configEntzy.SOCIALS.POST_LIMIT_DAYS +
            " days."
          : params.boost
          ? "Are you sure you want to boost this post? This will start a mini ad campaign and you will be charged the fixed price shown below."
          : "Are you sure you want to perform this action?",
        cancel: (e) => handleConfirmActionSocial(e, false, social, params),
        confirm: (e) => handleConfirmActionSocial(e, true, social, params),
      });
    }
  };

  const handleActionSocial = async (social, params) => {
    setLoading(true);
    const response = await eventContext.preparePostSocials({
      EventId: eventContext.state.event.data.EventId,
      SocialId: social.id,
      ...params,
    });
    if (response.alert) {
      mainContext.updateAlert(response);
    } else if (response.data && response.data.status === "stopped") {
      mainContext.updateAlert({
        alert: true,
        message: response.data.message,
      });
    } else {
      social.campaign = "test";
      eventContext.postSocials(social);
    }
    // delay loader reset to allow for publish callback
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  return (
    <Box
      id={anchorTop}
      className="box-default"
      sx={{
        pt: configEntzy.APP_SPACING_MD,
        pl: configEntzy.APP_SPACING_MD,
        pr: configEntzy.APP_SPACING_MD,
        pb: configEntzy.APP_SPACING_LG,
        mb: configEntzy.APP_SPACING_XL,
      }}
    >
      <Box className="box-default">
        <Typography variant="h6" color="primary">
          Social Boosters
        </Typography>
        <Typography variant="subtitle1" color="white">
          <em>Broadcast your eventuator on the Entzy Socials</em>
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            mt: configEntzy.APP_SPACING_MD,
          }}
        >
          <FontAwesomeIcon icon={iconInfo} fixedWidth />
          <span>&nbsp;&nbsp;This is an early stage capability&nbsp;&nbsp;</span>
          <FontAwesomeIcon icon={iconInfo} fixedWidth />
        </Typography>
      </Box>
      <Box
        className="box-default"
        sx={{
          pt: configEntzy.APP_SPACING_LG,
        }}
      >
        <Stepper alternativeLabel>
          {steps.map((label) => (
            <Step key={label} active={true}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      {loading ? (
        <Box
          className="box-default"
          sx={{
            pt: configEntzy.APP_SPACING_HL,
          }}
        >
          <ActionLoader />
        </Box>
      ) : (
        user.connected && (
          <Box
            className="box-default"
            sx={{
              mt: configEntzy.APP_SPACING_LG,
            }}
          >
            {eventContext.state.socials.map((social) => (
              <Box
                className="box-default bg-black"
                key={social.name}
                sx={{
                  mt: configEntzy.APP_SPACING_MD,
                  p: configEntzy.APP_SPACING_MD,
                  borderRadius: configEntzy.BORDER_SIZE_LG,
                }}
              >
                <Box className="box-default zero-height text-right">
                  <Box
                    className="box-default"
                    sx={{
                      p: configEntzy.APP_SPACING_MD,
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      color={social.active ? "black" : "dusk.main"}
                    >
                      <FontAwesomeIcon
                        icon={social.icon}
                        transform="grow-10"
                        fixedWidth
                        style={{
                          position: "relative",
                          zIndex: 99,
                        }}
                      />
                    </Typography>
                  </Box>
                </Box>

                {eventContext.state.event.data.Socials &&
                eventContext.state.event.data.Socials.find(
                  (s) => s.SocialId === social.id
                ) &&
                eventContext.state.event.data.Socials.find(
                  (s) => s.SocialId === social.id
                ).SocialAction +
                  eventContext.state.event.data.Socials.find(
                    (s) => s.SocialId === social.id
                  ).SocialStatus !==
                  "deletesuccess" ? (
                  <Box className="box-default">
                    <Box
                      className="box-default"
                      sx={{
                        pb: configEntzy.APP_SPACING_MD,
                      }}
                    >
                      <Typography
                        variant="h6"
                        className="upper-case"
                        color="orange"
                      >
                        <span>{social.name}</span>
                      </Typography>
                    </Box>
                    <Box className="box-default">
                      <ActiveTag
                        prefix="Post"
                        value={
                          eventContext.state.event.data.Socials.find(
                            (s) => s.SocialId === social.id
                          ).SocialStatus === "success"
                        }
                      />
                    </Box>
                    {eventContext.state.event.data.Socials.find(
                      (s) => s.SocialId === social.id
                    ).SocialStatus !== "success" && (
                      <Box
                        className="box-default"
                        sx={{
                          p: configEntzy.APP_SPACING_MD,
                        }}
                      >
                        <Typography variant="subtitle1" color="red">
                          <span>Post is in status </span>
                          <span>
                            {
                              eventContext.state.event.data.Socials.find(
                                (s) => s.SocialId === social.id
                              ).SocialStatus
                            }
                          </span>
                          <span>. </span>
                          <span>
                            {
                              jsonTryParse(
                                eventContext.state.event.data.Socials.find(
                                  (s) => s.SocialId === social.id
                                ).Meta
                              ).Response
                            }
                          </span>
                        </Typography>
                      </Box>
                    )}
                    <Box
                      className="box-default"
                      sx={{
                        mt: configEntzy.APP_SPACING_MD,
                        mb: configEntzy.APP_SPACING_MD,
                      }}
                    >
                      <CopyButton
                        infoText="Copy Post URL"
                        text={
                          eventContext.state.event.data.Socials.find(
                            (s) => s.SocialId === social.id
                          ).PostUrl
                        }
                        color="white"
                      />
                    </Box>
                    <Box
                      className="box-default"
                      sx={{
                        mt: configEntzy.APP_SPACING_MD,
                      }}
                    >
                      <Box
                        className="box-default"
                        sx={{
                          width: "33%",
                        }}
                      >
                        <ActionBoxButton
                          size="small"
                          bgColor="danger.dark"
                          color="white"
                          text="Delete"
                          disabled={!social.active}
                          onClick={() =>
                            handleConfirmActionSocial(null, null, social, {
                              delete: true,
                              PostId:
                                eventContext.state.event.data.Socials.find(
                                  (s) => s.SocialId === social.id
                                ).PostId,
                            })
                          }
                        />
                      </Box>
                      <Box
                        className="box-default"
                        sx={{
                          width: "34%",
                          pl: configEntzy.APP_SPACING_XS,
                          pr: configEntzy.APP_SPACING_XS,
                        }}
                      >
                        <ActionBoxButton
                          size="small"
                          bgColor="primary.light"
                          color="white"
                          text="Repost"
                          disabled={
                            !social.active ||
                            eventContext.state.event.data.Socials.find(
                              (s) => s.SocialId === social.id
                            ).SocialStatus !== "success"
                          }
                          onClick={
                            !social.active ||
                            eventContext.state.event.data.Socials.find(
                              (s) => s.SocialId === social.id
                            ).SocialStatus !== "success"
                              ? null
                              : () =>
                                  handleConfirmActionSocial(
                                    null,
                                    null,
                                    social,
                                    {
                                      repost: true,
                                      PostId:
                                        eventContext.state.event.data.Socials.find(
                                          (s) => s.SocialId === social.id
                                        ).PostId,
                                    }
                                  )
                          }
                        />
                      </Box>
                      <Box
                        className="box-default"
                        sx={{
                          width: "33%",
                        }}
                      >
                        <ActionBoxButton
                          size="small"
                          bgColor="primary.dark"
                          color="white"
                          text="Boost"
                          disabled={true}
                          // disabled={
                          //   !social.active ||
                          //   eventContext.state.event.data.Socials.find(
                          //     (s) => s.SocialId === social.id
                          //   ).SocialStatus !== "success"
                          // }
                          // onClick={
                          //   !social.active ||
                          //   eventContext.state.event.data.Socials.find(
                          //     (s) => s.SocialId === social.id
                          //   ).SocialStatus !== "success"
                          //     ? null
                          //     : () =>
                          //         handleConfirmActionSocial(
                          //           null,
                          //           null,
                          //           social,
                          //           {
                          //             boost: true,
                          //             PostId:
                          //               eventContext.state.event.data.Socials.find(
                          //                 (s) => s.SocialId === social.id
                          //               ).PostId,
                          //           }
                          //         )
                          // }
                        />
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <Box className="box-default">
                    <ActionBoxButton
                      bgColor={social.active ? "primary.light" : "dusk.main"}
                      color={social.active ? "black" : "white"}
                      text={social.name}
                      subtext={
                        social.active ? "Post your Eventuator" : "Coming Soon"
                      }
                      disabled={!social.active}
                      onClick={
                        social.active ? () => handleActionSocial(social) : null
                      }
                    />
                  </Box>
                )}
              </Box>
            ))}
          </Box>
        )
      )}
      <Box className="box-default">
        <ActionCheck
          dialog={dialog.visible}
          title={dialog.title}
          description={dialog.description}
          cancel={dialog.cancel}
          confirm={dialog.confirm}
        />
      </Box>
    </Box>
  );
}

export default ActionLaunchpadRunnerSocials;
